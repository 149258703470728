import { booleanAttribute, Component, computed, forwardRef, input, model, numberAttribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
  templateUrl: './input.component.html'
})
export class InputComponent implements ControlValueAccessor {
  protected onChange = (value: any) => {};
  protected onTouched = () => {};
  protected isDisabled = false;

  public id = input(crypto.randomUUID());
  public inputType = input('text');
  public value = model('');
  public placeholder = input('');
  public bgColor = input<'default' | 'white'>('default');
  public additionalClasses = input('');
  public autocomplete = input('off');
  public isLoading = input(false, { transform: booleanAttribute });
  public isRequired = input(false, { transform: booleanAttribute });
  public minLength = input(null, { transform: (v) => numberAttribute(v, null) });

  public bgColorClasses = computed(() => {
    switch (this.bgColor()) {
      case 'default': return 'bg-gray-50';
      case 'white': return 'bg-white';
    }
  })
  public classes = computed(() => {
    const baseClasses = 'border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-alfa1-brand-secondary-300 focus:ring-alfa1-brand-secondary-300 block w-full p-2.5';
    return `${baseClasses} ${this.bgColorClasses()} ${this.additionalClasses()}`;
  });

  public onInputChange(value: string): void {
    this.value.set(value);
    this.onChange(value);
  }

  public writeValue(value: string): void {
    this.value.set(value || '');
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

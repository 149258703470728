import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {first} from 'rxjs/operators';
import {Professional} from '../../model/professional';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {
  private meSubject = new ReplaySubject<Professional>(1);
  private meRequested = false;

  private professionalsSubject = new ReplaySubject<Professional[]>(1);
  private professionalsRequested = false;

  constructor(private http: HttpClient) {
  }

  public me(): Observable<Professional> {
    if (!this.meRequested) {
      this.meRequested = true;
      this.http
        .get<Professional>(`/api/professionals/me`)
        .subscribe(userDto => this.meSubject.next(userDto));
    }
    return this.meSubject.pipe(first());
  }

  public getAll(): Observable<Professional[]> {
    if (!this.professionalsRequested) {
      this.professionalsRequested = true;
      this.http
        .get<Professional[]>(`/api/professionals`)
        .subscribe(professionals => this.professionalsSubject.next(professionals));
    }

    return this.professionalsSubject.pipe(first());
  }
}

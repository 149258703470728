import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Certificate } from 'src/app/model/certificate';
import { Profile } from 'src/app/model/profile';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';

@Component({
  selector: 'app-certificate-form',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePickerDirective,
    FormButtonsComponent,
    FormInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './certificate-form.component.html',
})
export class CertificateFormComponent extends BaseProfileDataForm<Certificate> {
  @Input()
  public id: string;
  public dataName = $localize`training or certificate`;

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService, private dateService: DateService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, certificateId: string): Certificate | null {
    return profile.certificates.find(certificate => certificate.id === certificateId);
  }

  protected createForm(certificate: Certificate | null): FormGroup {
    return new FormGroup({
      id: new FormControl(certificate?.id),
      name: new FormControl(certificate?.name, Validators.required),
      issueDate: new FormControl(this.dateService.toUiDate(certificate?.issueDate), Validators.required)
    });
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addCertificate(
      profileId,
      {
        id: form.value['id'],
        name: form.value['name'],
        issueDate: this.dateService.toApiDate(form.value['issueDate'])
      });
  }
}

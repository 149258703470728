import { Routes } from '@angular/router';

import { authenticationGuard } from "./core/authentication/authentication.guard";
import { ForbiddenComponent } from "./core/forbidden/forbidden.component";
import { UnauthorizedComponent } from "./core/unauthorized/unauthorized.component";
import { PersonalDetailsComponent } from './profile/components/personal-details/personal-details.component';
import { AssignmentFormComponent } from './profile/components/assignment-form/assignment-form.component';
import { SummaryFormComponent } from './profile/components/summary-form/summary-form.component';
import { ListComponent } from './profile/components/list/list.component';
import { Summary } from './model/summary';
import { ListService } from './core/services/list.service';
import { SummaryListService } from './profile/services/summary-list.service';
import { Assignment } from './model/assignment';
import { AssignmentListService } from './profile/services/assignment-list.service';
import { ToolFormComponent } from './profile/components/tool-form/tool-form.component';
import { Tool } from './model/tool';
import { ToolListService } from './profile/services/tool-list.service';
import { Education } from './model/education';
import { EducationListService } from './profile/services/education.service';
import { EducationFormComponent } from './profile/components/education-form/education-form.component';
import { Certificate } from './model/certificate';
import { CertificateListService } from './profile/services/certificate-list.service';
import { CertificateFormComponent } from './profile/components/certificate-form/certificate-form.component';
import { DownloadComponent } from './download/download.component';
import { SoftSkillFormComponent } from './profile/components/soft-skill-form/soft-skill-form.component';
import { SoftSkillListService } from './profile/services/soft-skill-list.service';
import { AdminComponent } from './admin/admin.component';
import { blockNavIfChangesGuard } from './core/guards/block-nav-if-changes.guard';

export const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'forbidden', component: ForbiddenComponent, canActivate: [authenticationGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'personal-details', component: PersonalDetailsComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'summary/:id', component: SummaryFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'summary', component: ListComponent<Summary>, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: SummaryListService }] },
  { path: 'assignments/:id', component: AssignmentFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'assignments', component: ListComponent<Assignment>, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: AssignmentListService }] },
  { path: 'tools/:id', component: ToolFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'tools', component: ListComponent<Tool>, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: ToolListService }] },
  { path: 'education/:id', component: EducationFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'education', component: ListComponent<Education>, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: EducationListService }] },
  { path: 'certificates/:id', component: CertificateFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'certificates', component: ListComponent<Certificate>, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: CertificateListService }] },
  { path: 'soft-skills/:id', component: SoftSkillFormComponent, canActivate: [authenticationGuard], canDeactivate: [blockNavIfChangesGuard] },
  { path: 'soft-skills', component: ListComponent, canActivate: [authenticationGuard], providers: [{ provide: ListService, useClass: SoftSkillListService }] },
  { path: 'download', component: DownloadComponent, canActivate: [authenticationGuard], data: { hideBanner: true } },
  { path: '', redirectTo: '/personal-details', pathMatch: 'full' }
];

<div class="flex flex-col gap-10 md:max-w-screen-lg md:mx-auto">
  @if (errorMessage) {
    <p class="mb-2 text-red-600">{{ errorMessage }}</p>
  }
  @if (users$ | async; as users) {
    <app-change-role-form [users]="users" (onUserChanged)="onUserUpdated(users, $event)"></app-change-role-form>
    <hr>
    <app-create-user-form (onUserCreated)="onUserCreated(users, $event)"></app-create-user-form>
    <hr>
    <app-delete-user-form [users]="users" (onUserDeleted)="onUserDeleted(users, $event)"></app-delete-user-form>
  } @else {
    <div role="status" class="space-y-3.5 flex flex-col gap-4 w-full">
      <app-change-role-form isLoading="true"></app-change-role-form>
      <hr>
      <app-create-user-form isLoading="true"></app-create-user-form>
      <hr>
      <app-delete-user-form isLoading="true"></app-delete-user-form>
      <span class="sr-only">Loading...</span>
    </div>
  }
</div>
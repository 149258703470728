import { LowerCasePipe } from '@angular/common';
import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { ButtonComponent } from 'src/app/core/components/basic/button/button.component';
import { LabelComponent } from 'src/app/core/components/basic/label/label.component';
import { SelectComponent } from 'src/app/core/components/basic/select/select.component';
import { FormSelectComponent } from 'src/app/core/components/form/form-select/form-select.component';
import { UserService } from 'src/app/core/services/user.service';
import { Companies } from 'src/app/model/company.model';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-delete-user-form',
  standalone: true,
  imports: [
    ButtonComponent,
    FormSelectComponent,
    LabelComponent,
    LowerCasePipe,
    SelectComponent
  ],
  templateUrl: './delete-user-form.component.html'
})
export class DeleteUserFormComponent {
  protected selectedUser: User | null = null;
  protected isBusy = false;
  protected errorMessage = '';

  protected userOptions = computed(() => this.users().map(user => ({
    value: user.id,
    label: `${user.name} (${Companies.companyToName(user.company)})`
  })));

  public users = input<User[]>([]);
  public isLoading = input(false, { transform: booleanAttribute });
  public onUserDeleted = output<User>();

  constructor(private userService: UserService) {
  }

  public onUserSelect(userId: string): void {
    if (userId) {
      const userToSelect = this.users().find(user => user.id === userId);
      if (userToSelect !== undefined) {
        this.selectedUser = userToSelect;
      }
    } else {
      this.selectedUser = null;
    }
  }

  public onDelete(): void {
    if (this.selectedUser !== null) {
      this.errorMessage = '';
      this.isBusy = true;

      this.userService
        .delete(this.selectedUser.id)
        .subscribe({
          next: () => {
            const user = this.selectedUser;
            this.selectedUser = null;

            this.onUserDeleted.emit(user);
          },
          error: (err) => this.errorMessage = err.message,
          complete: () => this.isBusy = false
        });
    }
  }
}

import { booleanAttribute, Component, forwardRef, input, model, output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../basic/select/select-option.model';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { LabelComponent } from '../../basic/label/label.component';
import { SelectComponent } from "../../basic/select/select.component";

@Component({
  selector: 'app-form-select',
  standalone: true,
  imports: [
    FormErrorsComponent,
    LabelComponent,
    SelectComponent
],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true
    }
  ],
  templateUrl: './form-select.component.html'
})
export class FormSelectComponent<T> implements ControlValueAccessor {
  protected id = crypto.randomUUID();
  protected isDisabled = false;
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  public value = model('');
  public label = input.required<string>();
  public options = input<SelectOption<T>[]>([]);
  public placeholder = input('');
  public isLoading = input(false, { transform: booleanAttribute });
  public isRequired = input(false, { transform: booleanAttribute });
  public onSelectionChanged = output<string>();

  public onSelectChange(value: string): void {
    this.value.set(value);
    this.onChange(value);
    this.onSelectionChanged.emit(value);
  }

  public writeValue(value: string): void {
    this.value.set(value || '');
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

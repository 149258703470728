import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ListService } from 'src/app/core/services/list.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Certificate } from 'src/app/model/certificate';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';

@Injectable()
export class CertificateListService extends ListService<Certificate> {
  public listName = $localize`trainings & certificates`;

  constructor(private dateService: DateService, profileService: ProfileService) {
    super(profileService);
  }

  protected getListItems(profile: Profile): ListItem<Certificate>[] {
    return profile.certificates.map(certificate => ({
      id: certificate.id,
      title: certificate.name,
      subTitle: this.dateService.toUiDate(certificate.issueDate),
      originalItem: certificate
    }));
  }
  
  protected updateProfile(profile: Profile, elements: Certificate[]): void {
    profile.certificates = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteCertificate(profileId, elementId);
  }
}

<div class="flex flex-col gap-4 md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold">{{ title }}</h1>

  @if (errorMessage) {
    <p class="text-red-600">{{ errorMessage }}</p>
  } @else {
    @if (data$ | async; as data) {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <app-form-input i18n-label label="Name" formControlName="name" isRequired="true"></app-form-input>
        <app-form-select i18n-label label="Type" [options]="toolTypes" formControlName="toolType" isRequired="true"></app-form-select>
        <app-form-select i18n-label label="Level" [options]="toolLevels" formControlName="toolLevel" isRequired="true"></app-form-select>
        @if (data.errorMessage) {
            <p class="text-red-600">{{ data.errorMessage }}</p>
        }
        <app-form-buttons [form]="data.form" [(formIsSubmitting)]="isSubmitting" (onCancel)="navigateBack()"></app-form-buttons>
      </form>
    } @else {
        <div role="status" class="flex flex-col gap-4 animate-pulse">
          <app-form-input i18n-label label="Name" isLoading="true"></app-form-input>
          <app-form-select i18n-label label="Type" isLoading="true"></app-form-select>
          <app-form-select i18n-label label="Level" isLoading="true"></app-form-select>
            <span class="sr-only">Loading...</span>
        </div>
    }
  }
</div>

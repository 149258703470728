import { booleanAttribute, Component, forwardRef, Input, numberAttribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { LabelComponent } from '../../basic/label/label.component';

@Component({
  selector: 'app-form-textarea',
  standalone: true,
  imports: [
    FormErrorsComponent,
    LabelComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextareaComponent),
      multi: true
    }
  ],
  templateUrl: './form-textarea.component.html'
})
export class FormTextareaComponent implements ControlValueAccessor {
  protected id = crypto.randomUUID();
  protected value = '';
  protected isDisabled = false;
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  @Input({ required: true })
  public label = '';

  @Input()
  public placeholder = '';

  @Input()
  public bgColor: 'white' | 'default' = 'default';

  @Input({ transform: numberAttribute })
  public rows = 4;

  @Input({ transform: booleanAttribute })
  public isLoading = false;

  @Input({ transform: booleanAttribute })
  public isRequired = false;

  public onInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;

    this.onChange(this.value);
  }

  public writeValue(value: string): void {
    this.value = value || '';
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

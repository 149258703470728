import { Component } from '@angular/core';

@Component({
  selector: 'app-form-language-container',
  standalone: true,
  imports: [],
  templateUrl: './form-language-container.component.html'
})
export class FormLanguageContainerComponent {
}

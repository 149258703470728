import {Component, inject, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Observable, Subscription} from 'rxjs';


@Component({template: ''})
export abstract class ChangeTracker {
  constructor(private router: Router) {
  }

  public abstract hasChanges(): boolean;

  public forceNavigateRelativeTo(relativeTo: ActivatedRoute, url: string): void {
    this.router.navigate([url], {relativeTo, state: {force: true}});
  }
}


@Component({template: ''})
export abstract class ChangeTrackerForm extends ChangeTracker implements OnDestroy {
  private subscription: Subscription | null = null;
  private initialValue: any | null = null;
  private currentValue: any | null = null;

  protected abstract areFormValuesEqual(initialValue: any, currentValue: any): boolean;

  protected trackReset(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;

    this.initialValue = null;
    this.currentValue = null;
  }

  protected trackForm(form: FormGroup): void {
    this.initialValue = form.value;

    this.subscription = form.valueChanges.subscribe(() => {
      this.currentValue = form.value;
    });
  }

  protected trackedFormSubmitted(): void {
    this.initialValue = this.currentValue;
    this.currentValue = null;
  }

  public hasChanges(): boolean {
    return this.currentValue !== null && !this.areFormValuesEqual(this.initialValue, this.currentValue);
  }

  public ngOnDestroy(): void {
    this.trackReset();
  }
}


export function blockNavIfChangesGuard<T extends ChangeTracker>(component: T,
                                                                currentRoute: ActivatedRouteSnapshot,
                                                                currentState: RouterStateSnapshot,
                                                                nextState: RouterStateSnapshot): MaybeAsync<GuardResult> {
  if (!(component instanceof ChangeTracker)) {
    console.error(`The component '${currentRoute.component.name}' does not extend the type 'ChangeTrackerForm'.`);
    return true;
  }

  const router = inject(Router);
  const currentNav = router.getCurrentNavigation();
  const isForced = currentNav.extras.state ? currentNav.extras.state.force : false;

  return new Observable((subscriber) => {
    const choice = component.hasChanges() && !isForced ? confirm($localize`There are unsaved changes that will be lost. Are you sure you want to leave this page?`) : true;
    subscriber.next(choice);
    subscriber.complete();
  });
}

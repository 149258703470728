import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService, Token} from '../../services/auth.service';
import {AsyncPipe} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {ProfilePhotoComponent} from '../profile-photo/profile-photo.component';
import {ProfessionalService} from '../../services/professional.service';
import {Professional} from '../../../model/professional';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  imports: [
    AsyncPipe,
    ProfilePhotoComponent,
    RouterLink,
    RouterLinkActive,
  ]
})
export class NavBarComponent implements OnInit {
  professional$: Observable<Professional>;
  token$: Observable<Token>;

  constructor(
    private authService: AuthService,
    private professionalService: ProfessionalService) {
  }

  public ngOnInit(): void {
    this.professional$ = this.professionalService.me();
    this.token$ = this.authService.getIdTokenClaims();
  }
}

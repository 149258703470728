<nav class="bg-white sticky top-0 z-30 shadow-sm">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <button data-drawer-target="sidebar-default" data-drawer-toggle="sidebar-default" aria-controls="sidebar-default" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
      </svg>
   </button>
   <div>
    <img src="/alfa1.svg" alt="alfa1 logo">
   </div>
   <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-alfa1-brand-secondary-700 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
    <span class="sr-only">Open main menu</span>
    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
    </svg>
   </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul class="flex flex-col p-4 md:p-0 mt-4 text-alfa1-accent-secondary-100 md:text-alfa1-brand-secondary-700 border items-center text-center gap-1 md:gap-0 border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
        @if (data$ | async; as data) {
          @if (data.currentUser.isProfessional) {
            <li class="w-full md:w-auto">
              <a routerLink="/download" routerLinkActive="font-semibold" [routerLinkActiveOptions]="{exact: true}" class="block py-2 px-3 bg-alfa1-brand-primary-500 w-full rounded md:bg-transparent hover:underline lowercase md:p-0" aria-current="page">
                <span i18n>Download CV</span>
              </a>
            </li>
          }
          @if (data.currentUser.isAdmin || data.currentUser.isSales) {
            <li>
              <a routerLink="/admin" routerLinkActive="font-semibold" [routerLinkActiveOptions]="{exact: true}" class="block py-2 px-3 bg-alfa1-brand-primary-500 w-full rounded md:bg-transparent hover:underline lowercase md:p-0" aria-current="page">
                <span i18n>Admin</span>
              </a>
            </li>
          }
          
          <li class="flex flex-row items-center justify-center text-center bg-alfa1-brand-primary-500 md:bg-transparent gap-0 md:gap-2 w-full md:w-auto">
            <app-profile-photo [input]="{ profileId: data.currentUserProfile.id, photoUrl: data.currentUserProfile.professional.photoUrl }" class="w-6 h-6 md:w-8 md:h-8"></app-profile-photo>
            <span>{{ data.currentUserProfile.professional.fullName }}</span>
          </li>
        } @else {
          @for (_ of [].constructor(2); track $index) {
            <li>
              <div class="h-3.5 w-24 bg-gray-300 rounded-full"></div>
            </li>
          }
          <li class="flex flex-row items-center justify-center text-center bg-alfa1-brand-primary-500 md:bg-transparent gap-0 md:gap-2 w-full md:w-auto">
            <div role="status" class="space-y-3.5 max-w-lg">
              <div class="flex items-center w-full">
                  <app-profile-photo input="isLoading" class="w-6 h-6 me-4 md:w-8 md:h-8"></app-profile-photo>
                  <div class="h-3.5 bg-gray-300 rounded-full w-28 animate-pulse"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>
        </li>
        }
      </ul>
    </div>
  </div>
</nav>

import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { combineLatest, Observable } from 'rxjs';
import { Profile } from 'src/app/model/profile';
import { AsyncPipe } from '@angular/common';
import { UserService } from '../../services/user.service';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-active-profile-banner',
  standalone: true,
  imports: [
    AsyncPipe
  ],
  templateUrl: './active-profile-banner.component.html'
})
export class ActiveProfileBannerComponent implements OnInit {
  public static hiddenOnPages = [
    '/download'
  ];

  public data$: Observable<{ hidden: boolean, activeProfile: Profile | null }>;

  constructor(
    private router: Router,
    private userService: UserService,
    private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.data$ = combineLatest([this.getHidden(), this.getActiveProfile()]).pipe(
      map(([hidden, activeProfile]) => ({ hidden: hidden, activeProfile: activeProfile }))
    );
  }

  public onClose() {
    this.profileService
      .clearActiveProfile()
      .subscribe({
        error: (error) => {
          console.log(error);
        }
      });
  }

  private getHidden(): Observable<boolean> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        for (const pageUrl of ActiveProfileBannerComponent.hiddenOnPages) {
          if (event.url.toLowerCase().startsWith(pageUrl)) {
            return true;
          }
        }
        return false;
      })
    );
  }

  private getActiveProfile(): Observable<Profile | null> {
    return combineLatest([this.userService.getCurrent(), this.profileService.getActiveProfile()]).pipe(
      map(([currentUser, activeProfile]) => currentUser.profileId !== activeProfile.id ? activeProfile : null)
    );
  }
}

<div class="alfa1-gradient">
    <div
        class="max-w-screen-xl flex flex-col md:flex-row flex-wrap md:items-center justify-between mx-auto px-12 py-4 md:p-4 gap-y-8 text-white">
        <div>
            <h3 class="text-lg font-semibold">PRO</h3>
            <small class="text-gray-300">{{ deploymentInfo$ | async }}</small>
        </div>
        <div class="md:min-w-64">
            <h3 class="text-lg font-semibold mb-2">links</h3>
            <div class="flex flex-col flex-wrap gap-1 text-sm mb-2 max-w-full md:max-w-72">
                <a href="https://www.alfa1.com/privacy" target="_blank" class="hover:underline">Privacy statement</a>
                <a href="https://smoelenboek.alfa1.com/" target="_blank" class="hover:underline">Smoelenboek</a>
            </div>
        </div>
    </div>
</div>
<div class="flex flex-col gap-4 md:flex-row">
    <div class="flex-1 flex flex-col gap-2">
        <h2 i18n class="text-sm font-semibold text-gray-500">Dutch</h2>
        <div class="flex-1 rounded p-4 bg-gray-50 border border-gray-100 [&>*]:flex [&>*]:flex-col [&>*]:gap-4">
            <ng-content select="[slot=dutch]"></ng-content>
        </div>
    </div>
    <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
    <div class="flex-1 flex flex-col gap-2">
        <h2 i18n class="text-sm font-semibold text-gray-500">English</h2>
        <div class="flex-1 rounded p-4 bg-gray-50 border border-gray-100 [&>*]:flex [&>*]:flex-col [&>*]:gap-4">
            <ng-content select="[slot=english]"></ng-content>
        </div>
    </div>
</div>

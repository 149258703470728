import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsMobileService {
  public isNotMobile(): boolean {
    const elem = document.getElementById('isNotMobile');
    if (!elem) {
      throw Error("Could not find element 'isNotMobile' in the document.");
    }

    return window.getComputedStyle(elem).visibility == 'visible';
  }

  public isMobile(): boolean {
    return !this.isNotMobile();
  }
}

import { booleanAttribute, Component, forwardRef, Input, numberAttribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormErrorsComponent } from "../form-errors/form-errors.component";
import { LabelComponent } from '../../basic/label/label.component';
import { InputComponent } from '../../basic/input/input.component';

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [
    FormErrorsComponent,
    LabelComponent,
    InputComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ],
  templateUrl: './form-input.component.html'
})
export class FormInputComponent implements ControlValueAccessor {
  protected id = crypto.randomUUID();
  protected value = '';
  protected isDisabled = false;
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  @Input({ required: true })
  public label = '';

  @Input()
  public placeholder = '';

  @Input()
  public bgColor: 'white' | 'default' = 'default';

  @Input()
  public autocomplete = 'off';

  @Input()
  public inputType = 'text';

  @Input()
  public additionalClasses = '';

  @Input({ transform: booleanAttribute })
  public isLoading = false;

  @Input({ transform: booleanAttribute })
  public isRequired = false;

  @Input({ transform: (v: any) => numberAttribute(v, null) })
  public minLength: number | null = null;

  public onInputChange(value: string): void {
    this.value = value;
    this.onChange(this.value);
  }

  public writeValue(value: string): void {
    this.value = value || '';
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

import { Component, OnInit } from '@angular/core';
import { OidcSecurityService, LoginResponse } from "angular-auth-oidc-client";
import { Router, RouterOutlet} from "@angular/router";
import { initFlowbite } from 'flowbite';
import { NavBarComponent } from './core/components/nav-bar/nav-bar.component';
import { FooterComponent } from "./core/components/footer/footer.component";
import { SideBarComponent } from './core/components/side-bar/side-bar.component';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import { ActiveProfileBannerComponent } from './core/components/active-profile-banner/active-profile-banner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ActiveProfileBannerComponent,
    NavBarComponent,
    RouterOutlet,
    SideBarComponent,
    FooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  private localStorageRedirectKey = 'redirect';

  public title = 'PRO';
  public hasUserProfile = false;
  public isAuthenticated = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router) {
  }

  public ngOnInit(): void {
    initFlowbite();
    this.initMobileDragAndDrop();
    
    this.oidcSecurityService
      .checkAuth()
      .subscribe((response: LoginResponse) => {
        if (!response.isAuthenticated) {
          if ('/autologin' !== window.location.pathname) {
            this.storeRedirectPath(window.location.pathname);
            this.router.navigate(['/autologin']);
          }
        }
        if (response.isAuthenticated) {
          this.navigateToRedirectPath();
          this.isAuthenticated = true;
        }
      });
  }

  private navigateToRedirectPath(): void {
    const path = localStorage.getItem(this.localStorageRedirectKey);
    localStorage.removeItem(this.localStorageRedirectKey);

    if (!path || path === this.router.url) {
      return;
    }

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }

  private storeRedirectPath(path: string): void {
    localStorage.setItem(this.localStorageRedirectKey, path);
  }

  private initMobileDragAndDrop(): void {
    // See https://github.com/reppners/ngx-drag-drop for more information.

    polyfill( {
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
    } );
    
    // workaround to make scroll prevent work in iOS Safari >= 10
    try {
      window.addEventListener('touchmove', function() { }, { passive: false });
    }
    catch(e){}
  }
}

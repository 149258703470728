<div class="flex flex-col">
    <div class="flex flex-row gap-2">
        <app-checkbox #checkbox [(value)]="value" [bgColor]="bgColor" [isLoading]="isLoading" [isRequired]="isRequired" [isDisabled]="isDisabled" (onSelectionChanged)="onSelectionChange($event)" (blur)="onTouched()"></app-checkbox>
        <app-label [forId]="checkbox.id()" [value]="label" [isRequired]="isRequired" isBold="false"></app-label>
    </div>

    @if (!isLoading) {
        <app-form-errors [name]="label"></app-form-errors>
    }
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ListService } from 'src/app/core/services/list.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Education } from 'src/app/model/education';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';

@Injectable()
export class EducationListService extends ListService<Education> {
  public listName = $localize`educations`;

  constructor(private dateService: DateService, profileService: ProfileService) {
    super(profileService);
  }

  protected getListItems(profile: Profile): ListItem<Education>[] {
    return profile.educations.map(education => {
      const hasEndDate = education.endDate != null && education.endDate.trim() !== '';
      const endDateStr = hasEndDate ? this.dateService.toUiDate(education.endDate) : $localize`:Current moment in time:Present`;
      
      return {
        id: education.id,
        title: `${education.nameInstitute} - ${education.nameEducationNL}`,
        subTitle: `${this.dateService.toUiDate(education.startDate)} - ${endDateStr}`,
        originalItem: education
      }
    });
  }
  
  protected updateProfile(profile: Profile, elements: Education[]): void {
    profile.educations = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteEducation(profileId, elementId);
  }
}

<div class="flex flex-col h-screen w-screen overflow-hidden">
  <app-nav-bar></app-nav-bar>
  <div class="flex flex-row flex-1 overflow-hidden relative">
    <app-side-bar></app-side-bar>
    <div class="flex flex-col flex-1 overflow-hidden">
      <app-active-profile-banner></app-active-profile-banner>
      <div class="flex flex-col flex-1 overflow-auto">
        <div class="p-6 flex-1">
          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
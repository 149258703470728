import { booleanAttribute, Component, forwardRef, input, model, output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { SelectOption } from './select-option.model';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    InputComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  templateUrl: './select.component.html'
})
export class SelectComponent<T> implements ControlValueAccessor {
  protected isDisabled = false;
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  public id = input<string>(crypto.randomUUID());
  public value = model('');
  public options = input<SelectOption<T>[]>([]);
  public placeholder = input('');
  public isLoading = input(false, { transform: booleanAttribute });
  public isRequired = input(false, { transform: booleanAttribute });
  public onSelectionChanged = output<string>();

  public onSelectChange(value: string): void {
    this.value.set(value);
    this.onChange(value);
    this.onSelectionChanged.emit(value);
  }

  public writeValue(value: string): void {
    this.value.set(value || '');
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

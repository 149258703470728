<h1 i18n class="text-xl font-bold mb-4">Delete user</h1>

<div class="flex flex-col gap-4">
    @if (isLoading()) {
        <app-form-select i18n-label label="User" isLoading="true"></app-form-select>
    } @else {
        <div>
            <app-label i18n-value value="User" [forId]="userSelect.id()"></app-label>
            <app-select #userSelect [options]="userOptions()" [value]="selectedUser" isRequired="true" (onSelectionChanged)="onUserSelect($event)"></app-select>
        </div>
    }
    @if (errorMessage) {
        <p class="mt-4 text-red-600">{{ errorMessage }}</p>
    }
    <app-button color="primary" type="submit" i18n-label label="Delete" [isDisabled]="selectedUser == null" [isPerformingAction]="isBusy" (onBtnClick)="onDelete()"></app-button>
</div>

export class Tool {
  id: string;
  name: string;
  level: ToolLevel;
  toolType: ToolType
}

export enum ToolType {
  ROLE = 'ROLE',
  METHOD_TECHNIQUE = 'METHOD_TECHNIQUE',
  TOOL_APPLICATION = 'TOOL_APPLICATION',
  DEV_LANGUAGE = 'DEV_LANGUAGE',
  FRAMEWORK = 'FRAMEWORK',
  FRONTEND = 'FRONTEND',
  TESTING = 'TESTING',
  DEV_OPS = 'DEV_OPS',
  DATABASE = 'DATABASE',
  CLOUD = 'CLOUD',
}

export function toolTypeToName(toolType: ToolType): string {
  switch (toolType) {
    case ToolType.ROLE: return $localize`Role`;
    case ToolType.METHOD_TECHNIQUE: return $localize`Method or technique`;
    case ToolType.TOOL_APPLICATION: return $localize`Tool or application`;
    case ToolType.DEV_LANGUAGE: return $localize`Programming language`;
    case ToolType.FRAMEWORK: return $localize`Framework`;
    case ToolType.FRONTEND: return $localize`Frontend`;
    case ToolType.TESTING: return $localize`Testing`;
    case ToolType.DEV_OPS: return $localize`DevOps`;
    case ToolType.DATABASE: return $localize`Database`;
    case ToolType.CLOUD: return $localize`Cloud`;
  }
}

export enum ToolLevel {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  BASIC = 'BASIC',
  COMPETENT = 'COMPETENT',
  ADVANCED = 'ADVANCED',
  EXPERT = 'EXPERT'
}

export function toolLevelToName(toolLevel: ToolLevel): string {
  switch (toolLevel) {
    case ToolLevel.NOT_APPLICABLE: return $localize`Not applicable`;
    case ToolLevel.BASIC: return $localize`Basic`;
    case ToolLevel.COMPETENT: return $localize`Competent`;
    case ToolLevel.ADVANCED: return $localize`Advanced`;
    case ToolLevel.EXPERT: return $localize`Expert`;
  }
}

import { AfterViewInit, Component, ElementRef, Input, output, ViewChild } from '@angular/core';
import { Modal } from 'flowbite';

@Component({
  selector: 'app-modal-delete',
  standalone: true,
  imports: [],
  templateUrl: './modal-delete.component.html'
})
export class ModalDeleteComponent<T> implements AfterViewInit {
  private modal: Modal;
  private data: T | undefined;

  @ViewChild('modalElem')
  private modalElem: ElementRef;

  @Input({ required: true })
  public modalDeleteText: string;

  public errorMessage = '';
  public onDelete = output<T>();

  public ngAfterViewInit(): void {
    this.modal = new Modal(this.modalElem.nativeElement);
  }

  public open(data: T): void {
    this.data = data;
    this.modal.show();
  }

  public close(): void {
    this.data = undefined;
    this.modal.hide();
  }

  public confirm(): void {
    this.onDelete.emit(this.data);
    this.close();
  }
}

import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Profile } from 'src/app/model/profile';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { DropdownDirective } from '../../directives/dropdown.directive';

@Component({
  selector: 'app-profile-photo',
  standalone: true,
  imports: [
    DropdownDirective
  ],
  templateUrl: './profile-photo.component.html'
})
export class ProfilePhotoComponent {
  private static readonly MAX_PHOTO_SIZE_MB = 5;

  public input = input.required<{ profileId: string, photoUrl: string } | 'loading'>();
  public allowEdit = input(false, { transform: booleanAttribute });
  public onProfileChanged = output<Profile>();

  protected data = computed(() => {
    const params = this.input();
    return params === 'loading' ? null : params;
  });

  protected isPerformingAction = false;
  protected errorMessage = '';
  protected uploadProgress: number | null = null;

  constructor(private profileService: ProfileService) {
  }

  public deletePhoto(profileId: string): void {
    this.errorMessage = '';
    this.isPerformingAction = true;

    this.profileService
      .deleteCurrentPhoto(profileId)
      .subscribe({
        next: (profile) => this.onProfileChanged.emit(profile),
        error: (err: HttpErrorResponse) => this.errorMessage = err.message,
        complete: () => this.isPerformingAction = false
    });
  }

  public uploadPhoto(profileId: string, e: Event): void {
    const input = e.target as HTMLInputElement;
    if (input.files.length > 1) {
      this.errorMessage = $localize`Only one photo can be uploaded.`;
    } else if (input.files.length == 0) {
      this.errorMessage = $localize`Select a photo to upload.`;
    } else {
      const photo = input.files[0];

      if (photo.size > ProfilePhotoComponent.MAX_PHOTO_SIZE_MB * 1024 * 1024) {
        this.errorMessage = $localize`The photo cannot be larger than ${ProfilePhotoComponent.MAX_PHOTO_SIZE_MB}MB.`;
      } else {
        this.errorMessage = '';
        this.isPerformingAction = true;
        this.uploadProgress = 0;

        this.profileService.uploadPhoto(profileId, photo).subscribe({
          next: (event: HttpEvent<Profile>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.uploadProgress = 0;
                break;
              case HttpEventType.UploadProgress:
                this.uploadProgress = event.loaded / event.total;
                break;
              case HttpEventType.Response:
                this.onProfileChanged.emit(event.body);
                break;
            }
          },
          error: (err: HttpErrorResponse) => this.errorMessage = err.message,
          complete: () => {
            this.uploadProgress = null;
            this.isPerformingAction = false;
          }
        });
      }
    }
  }
}

import { booleanAttribute, Component, forwardRef, input, model, output } from '@angular/core';
import { LabelComponent } from '../label/label.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [
    LabelComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent implements ControlValueAccessor {
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  public id = input<string>(crypto.randomUUID());
  public value = model(false);
  public bgColor = input<'white' | 'default'>('default');
  public isLoading = input(false, { transform: booleanAttribute });
  public isRequired = input(false, { transform: booleanAttribute });
  public isDisabled = model(false);
  public onSelectionChanged = output<boolean>();

  public onInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    this.value.set(input.checked);

    this.onChange(input.checked);
    this.onSelectionChanged.emit(input.checked);
  }

  public writeValue(value: boolean): void {
    this.value.set(value || false);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from "../core/services/user.service";
import { User } from "../model/user";
import { ReplaySubject } from "rxjs";
import { AsyncPipe } from '@angular/common';
import { ChangeRoleFormComponent } from './components/change-role-form/change-role-form.component';
import { CreateUserFormComponent } from './components/create-user-form/create-user-form.component';
import { DeleteUserFormComponent } from './components/delete-user-form/delete-user-form.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    AsyncPipe,
    ChangeRoleFormComponent,
    CreateUserFormComponent,
    DeleteUserFormComponent
  ],
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  protected users$ = new ReplaySubject<User[]>(1);
  protected errorMessage = '';

  constructor(private userService: UserService) {
  }

  public ngOnInit(): void {
    this.userService
      .getAll()
      .subscribe({
        next: (users) => this.users$.next(users.sort(User.compare)),
        error: (err: HttpErrorResponse) => this.errorMessage = err.message
      });
  }

  public onUserCreated(users: User[], createdUser: User): void {
    const newUsers = [...users];
    newUsers.push(createdUser);
    this.users$.next(newUsers.sort(User.compare));
  }

  public onUserUpdated(users: User[], updatedUser: User): void {
    const newUsers = [];

    for (const user of users) {
      if (user.id !== updatedUser.id) {
        newUsers.push(user);
      } else {
        newUsers.push(updatedUser);
      }
    }

    this.users$.next(newUsers);
  }

  public onUserDeleted(users: User[], userToDelete: User): void {
    this.users$.next(users.filter(user => user.id !== userToDelete.id));
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';

@Component({ template: '' })
export abstract class BaseProfileDataForm<T> implements OnInit {
  abstract id: string;
  abstract dataName: string;

  public title: string;
  public data$: Observable<ProFormData>;
  public errorMessage = '';
  public isSubmitting = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected profileService: ProfileService) {
  }

  protected abstract createForm(summary: T | null): FormGroup;
  protected abstract getInitialValue(profile: Profile, valueId: string): T | null;
  protected abstract save(profileId: string, form: FormGroup): Observable<any>;

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    const action = isCreate ? $localize`Create` : $localize`Edit`;

    this.title = `${action} ${this.dataName}`;
    this.data$ = this.profileService
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = this.getInitialValue(profile, this.id);
            if (!initialValue) {
              throw new Error($localize`Unknown` + '' + this.dataName);
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        }),
        catchError((err, caught) => {
          this.errorMessage = err.message;
          return caught;
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      data.form.disable();
      this.isSubmitting = true;

      this
        .save(data.profileId, data.form)
        .subscribe({
          next: _ => this.navigateBack(),
          error: (err: HttpErrorResponse) => {
            data.errorMessage = err.message;
          },
          complete: () => {
            this.isSubmitting = false;
            data.form.enable();
          }
        });
    }
  }

  public navigateBack(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  protected getFormControlArray(form: FormGroup, fieldName: string): FormArray {
    return form.get(fieldName) as FormArray;
  }
}

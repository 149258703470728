import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';
import { SoftSkill } from 'src/app/model/softSkill';

@Injectable()
export class SoftSkillListService extends ListService<SoftSkill> {
  public listName = $localize `Soft skills`;

  protected getListItems(profile: Profile): ListItem<SoftSkill>[] {
    return profile.softSkills.map(softSkill => ({
      id: softSkill.id,
      title: softSkill.nameSoftSkillNL,
      subTitle: '',
      originalItem: softSkill
    }));
  }
  
  protected updateProfile(profile: Profile, elements: SoftSkill[]): void {
    profile.softSkills = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteSoftSkill(profileId, elementId);
  }
}

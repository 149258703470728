@if (isLoading()) {
    <app-input [isLoading]="isLoading()"></app-input>
} @else {
    <select [id]="id()" [required]="isRequired()" (change)="onSelectChange($event.target.value)" (blur)="onTouched()" class="border border-gray-300 text-gray-900 text-sm rounded-lg bg-gray-50 focus:border-alfa1-brand-secondary-300 focus:ring-alfa1-brand-secondary-300 block w-full p-2.5">
        <option disabled hidden [selected]="!value()"></option>
        @for (option of options(); track option.value) {
            <option [value]="option.value" [selected]="option.value === value()">{{ option.label }}</option>
        }
    </select>
}

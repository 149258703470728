import {Injectable} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Role} from '../../model/role';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  getIdTokenClaims(): Observable<Token> {
    return this.oidcSecurityService.getIdToken().pipe(
      map((token: string) => (token ? this.parseJwt(token) : null))
    );
  }

  getAccessTokenClaims(): Observable<Token> {
    return this.oidcSecurityService.getAccessToken().pipe(
      map((token: string) => (token ? this.parseJwt(token) : null))
    );
  }

  private parseJwt(token: string): Token {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const obj = JSON.parse(atob(base64));
    return new Token(obj.sid, obj.sub, obj.email, obj.name, obj.roles);
  }
}

export class Token {
  constructor(public sid: string, public sub: string, public email: string, public name: string, public roles: string[]) {
  }

  public get isSales(): boolean {
    return this.hasRole(Role.SALES);
  }

  public get isAdmin(): boolean {
    return this.hasRole(Role.ADMIN);
  }

  public get isProfessional(): boolean {
    return this.hasRole(Role.PROFESSIONAL);
  }

  public hasRole(role: Role): boolean {
    if (this.roles) {
      return this.roles.includes(role);
    }
    return false;
  }

  public hasNotRole(role: Role): boolean {
    if (this.roles) {
      return !this.roles.includes(role);
    }
    return false;
  }
}

export const emptyToken = new Token('', '', '', '', []);

import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateService } from '../services/date.service';

export const checkDateBefore = (dateService: DateService, beforeDate: string, afterDate: string): ValidatorFn => {
    return function(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormGroup) {
            const beforeDateControl = control.get(beforeDate);
            const afterDateControl = control.get(afterDate);

            const comparison = dateService.compareUiDate(beforeDateControl.value, afterDateControl.value);
            if (comparison >= 0 && (beforeDateControl.dirty || afterDateControl.dirty)) {
              return { dateBefore: `The date ${beforeDateControl.value} must be before date ${afterDateControl.value}.` };
            }
        } else {
            console.error('This validator can only be applied to form groups.');
            return null;
        }
    };
};

/**
 * This validation can only be applied on FormControl, not on FormGroup
 * @param dateService the dateService
 * @param afterDate afterDate is a date string in DD-MM-YYYY format. For example moment().format("DD-MM-YYYY")
 * @returns ValidatorFn that checks whether or not the given date inside the FormControl is after the afterDate or not.
 * Same date is accepted
 */
export const checkDateBeforeMoment = (dateService: DateService, afterDate: string): ValidatorFn => {
    return function(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            const controlDate = control.value;

            const comparison = dateService.compareUiDate(controlDate, afterDate);
            if (comparison > 0) {
              return { dateBefore: `The date ${controlDate} must be before date ${afterDate}.` };
            }
        } else {
            console.error('This validator can only be applied to form controls.');
            return null;
        }
    };
};

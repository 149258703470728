import { booleanAttribute, Component, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DndDraggableDirective, DndDropEvent, DndDropzoneDirective, DndHandleDirective } from 'ngx-drag-drop';
import { ModalDeleteComponent } from '../../modal-delete/modal-delete.component';
import { InputComponent } from '../../basic/input/input.component';

@Component({
  selector: 'app-form-tools',
  standalone: true,
  imports: [
    DndDraggableDirective,
    DndDropzoneDirective,
    DndHandleDirective,
    InputComponent,
    ModalDeleteComponent,
    ReactiveFormsModule
  ],
  templateUrl: './form-tools.component.html'
})
export class FormToolsComponent {
  @Input()
  public controlArray: FormArray;

  @Input({ transform: booleanAttribute })
  public isLoading = false;

  @ViewChild('modalDelete')
  public modalDelete: ModalDeleteComponent<number>;

  public onDropTool(event: DndDropEvent) {
    if (event.dropEffect === 'move') {
      const currentIdx = event.data as number;
      const newIdx = event.index;
      
      const newIdxInArray = newIdx ?? this.controlArray.length;
      const controlToBeMoved = this.controlArray.at(currentIdx);
  
      if (newIdxInArray < currentIdx) {
        this.controlArray.removeAt(currentIdx);
        this.controlArray.insert(newIdxInArray, controlToBeMoved);
        this.controlArray.markAsDirty();
      } else if (currentIdx < newIdxInArray) {
        this.controlArray.insert(newIdxInArray, controlToBeMoved);
        this.controlArray.removeAt(currentIdx);
        this.controlArray.markAsDirty();
      }
    }
  }

  public onDeleteTool(idx: number) {
    const control = this.controlArray.at(idx);
    if (control) {
      if (control.value.trim() === '') {
        this.deleteTool(idx);
      } else {
        this.modalDelete.open(idx);
      }
    }
  }

  public addTool(): void {
    this.controlArray.push(new FormControl(''));
  }

  public deleteTool(idx: number): void {
    this.controlArray.removeAt(idx);
    this.controlArray.markAsDirty();
  }
}

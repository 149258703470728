@if (data$ | async; as data) {
    @if (!data.hidden && data.activeProfile !== null) {
        <div tabindex="-1" class="flex justify-between w-full p-4 border-b border-gray-100 bg-alfa1-brand-primary-100">
            <div class="flex items-center mx-auto">
                <p i18n class="flex items-center text-sm font-normal text-alfa1-brand-secondary-300">
                    Profile of {{ data.activeProfile.professional.fullName }} active
                </p>
            </div>
            <div class="flex items-center">
                <button type="button" class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-alfa1-brand-secondary-300 hover:text-alfa1-brand-secondary-500 rounded-lg text-sm p-1.5" (click)="onClose()">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Clear active profile</span>
                </button>
            </div>
        </div>
    }
}
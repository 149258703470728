import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Profile } from "../../model/profile";
import { Assignment } from "../../model/assignment";
import { combineLatest, EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { Education } from "../../model/education";
import { Certificate } from "../../model/certificate";
import { Tool } from "../../model/tool";
import { SoftSkill } from "../../model/softSkill";
import { Summary } from "../../model/summary";
import { UserService } from './user.service';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private static readonly ACTIVE_PROFILE_STORAGE_KEY = 'activeProfileId';
  private readonly activeProfileId$ = new ReplaySubject<string>(1);

  private currentUserProfileIsRetrieved = false;
  private currentUserProfileId: string = null;
  private currentUserProfile$ = new ReplaySubject<Profile>(1);

  constructor(
    private http: HttpClient,
    private userService: UserService) {
      const storedProfileId = localStorage.getItem(ProfileService.ACTIVE_PROFILE_STORAGE_KEY);
      this.activeProfileId$.next(storedProfileId);
  }

  public getCurrentUserProfile(): Observable<Profile> {
    if (!this.currentUserProfileIsRetrieved) {
      this.currentUserProfileIsRetrieved = true;
      this.userService
        .getCurrent()
        .pipe(switchMap(currentUser => this.getProfile(currentUser.profileId)))
        .subscribe((profile: Profile) => {
          this.currentUserProfileId = profile.id;
          this.currentUserProfile$.next(profile);
        });
    }
    return this.currentUserProfile$;
  }

  public setActiveProfile(profileId: string): void {
    localStorage.setItem(ProfileService.ACTIVE_PROFILE_STORAGE_KEY, profileId);
    this.activeProfileId$.next(profileId);
  }

  public getActiveProfileId(): Observable<string> {
    return combineLatest([this.userService.getCurrent(), this.activeProfileId$]).pipe(
      map(([user, storedProfileId]) => {
        if (user.isSales && storedProfileId) {
          return storedProfileId;
        } else {
          return user.profileId;
        }
      })
    );
  }

  public getActiveProfile(): Observable<Profile> {
    return this.getActiveProfileId().pipe(
      switchMap(profileId => this.getProfile(profileId)),
      catchError((err, _) => {
        const actionToFix$ = err instanceof HttpErrorResponse && err.status === 404
          ? this.clearActiveProfile()
          : EMPTY;
          
          return actionToFix$.pipe(
            switchMap(() => this.getActiveProfile())
          );
      })
    );
  }

  public clearActiveProfile(): Observable<void> {
    return this.userService
      .getCurrent()
      .pipe(
        tap(user => {
          localStorage.removeItem(ProfileService.ACTIVE_PROFILE_STORAGE_KEY);
          this.activeProfileId$.next(user.profileId)
        }),
        map(_ => {})
      );
  }

  public getAvailableProfiles(): Observable<Profile[]> {
    return this.http.get<Profile[]>(`/api/profiles`);
  }

  public getAvailableProfileIds(): Observable<string[]> {
    return this.http.get<string[]>(`/api/profiles/ids`);
  }

  public getProfile(profileId: string): Observable<Profile> {
    return this.http.get<Profile>(`/api/profiles/${profileId}`);
  }

  public createEmptyProfile(profileId: string): Observable<Profile> {
    return this.http.post<Profile>(`/api/profiles/${profileId}`, null);
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    return this.http
      .put<Profile>(`/api/profiles/${profile.id}`, profile)
      .pipe(
        tap(profile => this.notifyProfileHasChanged(profile))
      );
  }

  public addSummary(profileId: string, summary: Summary): Observable<Summary> {
    return this.http.post<Summary>(`/api/profiles/${profileId}/summary`, summary);
  }

  public deleteSummary(profileId: string, summaryId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/summary/${summaryId}`);
  }

  public addAssignment(profileId: string, assignment: Assignment): Observable<Assignment> {
    return this.http.post<Assignment>(`/api/profiles/${profileId}/assignment`, assignment);
  }

  public deleteAssignment(profileId: string, assignmentId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/assignment/${assignmentId}`);
  }

  public addEducation(profileId: string, education: Education): Observable<Education> {
    return this.http.post<Education>(`/api/profiles/${profileId}/education`, education);
  }

  public deleteEducation(profileId: string, educationId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/education/${educationId}`);
  }

  public addCertificate(profileId: string, certificate: Certificate): Observable<Certificate> {
    return this.http.post<Certificate>(`/api/profiles/${profileId}/certificate`, certificate);
  }

  public deleteCertificate(profileId: string, certificateId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/certificate/${certificateId}`);
  }

  public addTool(profileId: string, tool: Tool): Observable<Tool> {
    return this.http.post<Tool>(`/api/profiles/${profileId}/tool`, tool);
  }

  public deleteTool(profileId: string, toolId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/tool/${toolId}`);
  }

  public addSoftSkill(profileId: string, softSkill: SoftSkill): Observable<SoftSkill> {
    return this.http.post<SoftSkill>(`/api/profiles/${profileId}/softSkill`, softSkill);
  }

  public deleteSoftSkill(profileId: string, softSkillId: string): Observable<void> {
    return this.http.delete<void>(`/api/profiles/${profileId}/softSkill/${softSkillId}`);
  }

  public getCurrentPhoto(profileId: string): Observable<Blob> {
    return this.http.get(`/api/profiles/photo/${profileId}`, {responseType: 'blob'});
  }

  public uploadPhoto(profileId: string, photo: File): Observable<HttpEvent<Profile>> {
    const form = new FormData();
    form.append('file', photo);

    return this.http
      .post<void>(`/api/profiles/photo/${profileId}`, form, {
        observe: 'events', 
        reportProgress: true
      })
      .pipe(
        mergeMap(event => {
          if (event instanceof HttpResponse) {
            return this.getProfile(profileId).pipe(
              tap(profile => this.notifyProfileHasChanged(profile)),
              map(profile => event.clone({ body: profile }))
            );
          } else {
            return of(event);
          }
        })
      );
  }

  public deleteCurrentPhoto(profileId: string): Observable<Profile> {
    return this.http.delete<void>(`/api/profiles/photo/${profileId}`).pipe(
      mergeMap(() => this
        .getProfile(profileId)
        .pipe(
          tap(profile => this.notifyProfileHasChanged(profile))
        )
      )
    );
  }

  private notifyProfileHasChanged(profile: Profile): void {
    if (profile.id === this.currentUserProfileId) {
      this.currentUserProfile$.next(profile);
    }
  }
}

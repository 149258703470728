import { ValidatorFn } from '@angular/forms';
import { DateService } from '../services/date.service';
import { checkDateBeforeMoment } from './check-date-before';
import * as moment from 'moment';

/**
 * Validator can only be used on FormControl
 * @param dateService the dateService
 * @returns ValidatorFn that checks whether or not the given date inside the FormControl is in the past or not
 */
export const checkDateInPast = (dateService: DateService): ValidatorFn => {
    return checkDateBeforeMoment(dateService, moment().format('DD-MM-YYYY'));
};

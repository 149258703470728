import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  standalone: true,
  templateUrl: './form-errors.component.html'
})
export class FormErrorsComponent {
  @Input({ required: true })
  public name = '';

  constructor(private control: NgControl) {
  }

  public getErrors(): string[] {
    const errors = [];

    if (this.control.touched || this.control.dirty) {
      for (const propName in this.control.errors) {
        errors.push(this.getError(propName, this.control.errors[propName]));
      }
    }

    return errors;
  }

  private getError(errorCode: string, errorValue: any): string {
    switch (errorCode) {
      case 'required': return $localize`The ${this.name} is required.`;
      case 'minlength': return $localize`The ${this.name} has a minimum length of ${errorValue.requiredLength} characters.`;
      case 'dateAfter': return errorValue;
      case 'dateBefore': return errorValue;
      default: throw Error(`No error defined for code ${errorCode}.`);
    }
  }
}

import { booleanAttribute, Component, effect, input, model, output, signal } from '@angular/core';
import { ButtonComponent } from '../../basic/button/button.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-buttons',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './form-buttons.component.html'
})
export class FormButtonsComponent {
  public form = input.required<FormGroup>();
  public formIsSubmitting = model.required<boolean>();
  public submitText = input($localize`Save`);
  public canCancel = input(true, { transform: booleanAttribute });
  public mustBeDirtyToSubmit = input(true, { transform: booleanAttribute });
  public onCancel = output<void>();

  public canSubmit = signal(false);

  constructor() {
    effect((onCleanup) => {
      // Get the form such that, when it changes, this effect is executed again.
      const form = this.form();

      // Subscribe to the form its value changes as the form does not count as changed if any input changes.
      // On every input change, recalculate whether the form can be submitted.
      const subscription = form.valueChanges.subscribe(_ => this.setCanSubmit(form));

      // Calculate whether the form can be submitted initially.
      this.setCanSubmit(form);

      // Unsubscribe from the subscription once this effect is cleaned up.
      onCleanup(() => subscription.unsubscribe());
    }, { allowSignalWrites: true });
  }

  private setCanSubmit(form: FormGroup): void {
    const canSave = this.mustBeDirtyToSubmit() ? form.dirty : true;
    this.canSubmit.set(form.valid && canSave);
  }
}

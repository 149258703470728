import { booleanAttribute, Component, input } from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';
import { DateService } from 'src/app/core/services/date.service';
import { Assignment } from 'src/app/model/assignment';
import { CheckboxComponent } from '../../basic/checkbox/checkbox.component';
import { EllipsisDirective } from 'src/app/core/directives/ellipsis.directive';

@Component({
  selector: 'app-form-assignments',
  standalone: true,
  imports: [
    CheckboxComponent,
    EllipsisDirective,
    ReactiveFormsModule
  ],
  templateUrl: './form-assignments.component.html',

  // Apply the 'min-w-0' to this component. This is a workaround such that flexbox allows the overflow ellipsis.
  styles: ':host { @apply min-w-0; }'
})
export class FormAssignmentsComponent {
  public controlArray = input<FormArray>();
  public assignments = input<Assignment[]>([]);
  public isLoading = input(false, { transform: booleanAttribute });

  constructor(private dateService: DateService) {
  }

  protected dateString(assignment: Assignment): string {
    const endDateString = assignment.endDate
      ? this.dateService.toUiDate(assignment.endDate)
      : $localize`:Current moment in time:Present`;

    return `${this.dateService.toUiDate(assignment.startDate)} - ${endDateString}`;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from "../../model/user";
import { first, map } from "rxjs/operators";
import { UserDto } from '../data/user.data';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new ReplaySubject<User>(1);
  private userRequested = false;

  private allUsersSubject = new ReplaySubject<User[]>(1);
  private allUsersRequested = false;

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<User[]> {
    if (!this.allUsersRequested) {
      this.allUsersRequested = true;
      this.http
        .get<UserDto[]>(`/api/users`)
        .subscribe(userDtos => {
          const users = userDtos.map(user => UserService.userDtoToModel(user));
          this.allUsersSubject.next(users);
        });
    }

    return this.allUsersSubject.pipe(first());
  }

  public getCurrent(): Observable<User> {
      if (!this.userRequested) {
        this.userRequested = true;
        this.http
          .get<UserDto>(`/api/users/currentUser`)
          .subscribe(userDto => this.userSubject.next(UserService.userDtoToModel(userDto)));
      }
      return this.userSubject.pipe(first());
  }

  public get(userId): Observable<User> {
    return this.http
      .get<UserDto>(`/api/users/${userId}`)
      .pipe(map(dto => UserService.userDtoToModel(dto)));
  }

  public update(user: User): Observable<User> {
    return this.http
      .post<UserDto>('/api/users', user)
      .pipe(map(dto => UserService.userDtoToModel(dto)));
  }

  public create(name: string, email: string, companyName: string): Observable<User> {
    return this.http
      .put<UserDto>('/api/users', {
        name: name,
        email: email,
        company: companyName,
      })
      .pipe(map(dto => UserService.userDtoToModel(dto)));
  }

  public delete(userId: string): Observable<void> {
    return this.http.delete<void>(`/api/users/${userId}`);
  }

  private static userDtoToModel(dto: UserDto): User {
    return new User(dto.id, dto.profileId, dto.name, dto.email, dto.company, dto.roles);
  }
}

import { Component, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ImportRecruiteeComponent } from './components/import-recruitee/import-recruitee.component';
import { ChangeTracker } from '../core/guards/block-nav-if-changes.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    AsyncPipe,
    ImportRecruiteeComponent
],
  templateUrl: './admin.component.html'
})
export class AdminComponent extends ChangeTracker {
  protected errorMessage = '';

  public formImportRecruitee = viewChild<ImportRecruiteeComponent>('importRecruiteeForm');

  constructor(router: Router) {
    super(router);
  }

  public hasChanges(): boolean {
    return this.formImportRecruitee()?.hasChanges();
  }
}

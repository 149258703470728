import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstLetter',
  standalone: true
})
export class UpperCaseFirstLetterPipe implements PipeTransform {
  public transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== 'string' || value.length === 0) {
      return value;
    } else if (value.length === 1) {
      return value.toUpperCase();
    } else {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
}

import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { RecruiteeService } from 'src/app/core/services/recruitee.service';
import { User } from 'src/app/model/user';
import { FormSelectComponent } from '../../../core/components/form/form-select/form-select.component';
import { FormButtonsComponent } from '../../../core/components/form/form-buttons/form-buttons.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormInputComponent } from '../../../core/components/form/form-input/form-input.component';
import { ChangeTrackerForm } from 'src/app/core/guards/block-nav-if-changes.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-recruitee',
  standalone: true,
  imports: [
    FormInputComponent,
    FormSelectComponent,
    FormButtonsComponent,
    ReactiveFormsModule
],
  templateUrl: './import-recruitee.component.html'
})
export class ImportRecruiteeComponent extends ChangeTrackerForm {
  protected form = this.createForm();
  protected isBusy = false;
  protected errorMessage = '';
  protected successMessage = '';

  public isLoading = input(false, { transform: booleanAttribute });
  public onUserChanged = output<User>();

  constructor(private recruiteeService: RecruiteeService, router: Router) {
    super(router);
  }

  public onFormSubmit(): void {
    if (this.form.dirty && this.form.valid) {
      this.form.disable();
      this.errorMessage = '';
      this.successMessage = '';
      this.isBusy = true;

      const userId = this.form.value.user;
      const recruiteeId = this.form.value.recruiterId;

      this.recruiteeService
        .import(userId, recruiteeId)
        .subscribe({
          next: () => {
            this.successMessage = $localize`Recruitee profile has been successfully imported.`;
            this.form = this.createForm();
            this.isBusy = false;
          },
          error: (err) => {
            this.errorMessage = err.message;
            this.form.enable();
            this.isBusy = false;
          }
        });
    }
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue.user === currentValue.user
      && initialValue.recruiterId === currentValue.recruiteeId;
  }

  private createForm(): FormGroup {
    this.trackReset();
    const form =  new FormGroup({
      user: new FormControl('', [Validators.required, Validators.email]),
      recruiteeId: new FormControl('', Validators.required)
    });
    this.trackForm(form);
    return form;
  }
}

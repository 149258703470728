import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';
import { ProfileService } from './profile.service';

@Injectable()
export abstract class ListService<T> {
  private currentProfile: Profile;

  constructor(protected profileService: ProfileService) {
  }

  protected abstract getListItems(profile: Profile): ListItem<T>[];
  protected abstract updateProfile(profile: Profile, elements: T[]): void;
  protected abstract deleteElement(profileId: string, elementId: string): Observable<void>;

  public abstract listName: string;

  public getItems(): Observable<ListItem<T>[]> {
    return this.profileService
      .getActiveProfile()
      .pipe(
        tap((profile: Profile) => {
          this.currentProfile = profile;
        }),
        map(profile => this.getListItems(profile))
      );
  }

  public changeOrder(items: ListItem<T>[]): Observable<ListItem<T>[]> {
    this.updateProfile(this.currentProfile, items.map(item => item.originalItem));

    return this.profileService
      .updateProfile(this.currentProfile)
      .pipe(
        map(profile => this.getListItems(profile))
      );
  }

  public delete(itemId: string): Observable<ListItem<T>[]> {
    const newItems = this.getListItems(this.currentProfile).filter(item => item.id !== itemId);
    this.updateProfile(this.currentProfile, newItems.map(item => item.originalItem));

    return this
      .deleteElement(this.currentProfile.id, itemId)
      .pipe(
        map(() => newItems)
      );
  }
}

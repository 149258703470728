import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  private static requiresAuthorization(request: HttpRequest<unknown>): boolean {
    return request.url.startsWith('/api');
  }

  private static withAuthorizationToken<T>(request: HttpRequest<T>, token: string): HttpRequest<T> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (AuthHttpInterceptor.requiresAuthorization(request)) {
      return this.oidcSecurityService.isAuthenticated$.pipe(
        filter(result => result.isAuthenticated),
        switchMap(_ => this.oidcSecurityService.getIdToken()),
        take(1),
        map(token => AuthHttpInterceptor.withAuthorizationToken(request, token)),
        switchMap(authorizedRequest => next.handle(authorizedRequest))
      );
    }
    return next.handle(request);
  }
}

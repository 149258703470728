<h1 i18n class="text-xl font-bold mb-4">Change roles</h1>

<div class="flex flex-col gap-4">
    @if (isLoading()) {
        <app-form-select i18n-label label="User" isLoading="true"></app-form-select>
        <div class="flex flex-col gap-4 md:flex-row">
            <app-form-checkbox label="Professional" isLoading="true"></app-form-checkbox>
            <app-form-checkbox label="Sales" isLoading="true"></app-form-checkbox>
            <app-form-checkbox label="Admin" isLoading="true"></app-form-checkbox>
        </div>
    } @else {
        <div class="flex flex-col gap-2">
            <app-label i18n-value value="User" [forId]="userSelect.id()"></app-label>
            <app-select #userSelect [options]="userOptions()" isRequired="true" (onSelectionChanged)="onUserSelect($event)"></app-select>
        </div>
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="flex flex-col gap-4">
            <div class="flex flex-col gap-4 md:flex-row">
                <app-form-checkbox label="Professional" formControlName="isProfessional"></app-form-checkbox>
                <app-form-checkbox label="Sales" formControlName="isSales"></app-form-checkbox>
                <app-form-checkbox label="Admin" formControlName="isAdmin"></app-form-checkbox>
            </div>
            @if (errorMessage) {
                <p class="mt-4 text-red-600">{{ errorMessage }}</p>
            }
            <app-form-buttons [form]="form" [formIsSubmitting]="isBusy" i18n-submitText submitText="Update" canCancel="false"></app-form-buttons>
        </form>
    }
</div>
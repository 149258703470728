import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  readonly dateFormatUi: string;
  readonly dateFormatApi = 'YYYY-MM-DD';

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.dateFormatUi = moment.localeData(locale).longDateFormat('L');
  }

  public toApiDate(dateUi: string): string | null {
    if (!dateUi)
      return null;
    else
      return moment(dateUi, this.dateFormatUi).format(this.dateFormatApi);
  }

  public toUiDate(dateApi: string): string {
    if (!dateApi)
      return '';
    else
      return moment(dateApi, this.dateFormatApi).format(this.dateFormatUi);
  }

  public compareUiDate(dateUi1: string, dateUi2: string): number {
    if ((dateUi1 == null && dateUi2 == null) || (dateUi1.trim() === '' && dateUi2.trim() === '')) {
      return 0;
    } else if (dateUi1 == null || dateUi1.trim() === '') {
      return 1;
    } else if (dateUi2 == null || dateUi2.trim() === '') {
      return -1;
    } else {
      const dateUi1Unix = moment(dateUi1, this.dateFormatUi).unix();
      const dateUi2Unix = moment(dateUi2, this.dateFormatUi).unix();

      if (dateUi1Unix < dateUi2Unix) {
        return -1;
      } else if (dateUi1Unix > dateUi2Unix) {
        return 1;
      } else {
        return 0;
      }
    }
  }
}

<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">Personal details</h1>

  @if (data$ | async; as data) {
    <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data.profile, data.form)" class="flex flex-col gap-4">
      <div class="flex flex-col gap-x-12 flex-1 flex-wrap md:flex-row">
        <div class="flex flex-col gap-3 items-center mb-6 md:mb-0">
          <app-profile-photo [input]="{ profileId: data.profile.id, photoUrl: data.profile.professional.photoUrl }" allowEdit="true" (onProfileChanged)="profileChange$.next($event)" class="w-52"></app-profile-photo>
          <app-form-checkbox i18n-label label="Photo can be used in CV" formControlName="usePhoto"></app-form-checkbox>
        </div>
        <div class="flex flex-col flex-1 gap-4">
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="First name" autocomplete="given-name" formControlName="firstName" isRequired="true" minLength="2" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Last name" autocomplete="family-name" formControlName="lastName" isRequired="true" minLength="2" class="flex-1"></app-form-input>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="City of residence" autocomplete="address-level2" formControlName="placeOfResidence" isRequired="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Date of birth" appDatePicker autocomplete="bday" formControlName="dateOfBirth" isRequired="true" class="flex-1"></app-form-input>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="Title" autocomplete="organization-title" formControlName="title" isRequired="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Email" autocomplete="email" formControlName="emailAddress" class="flex-1"></app-form-input>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:items-end">
        @if (errorMessage) {
          <p class="mb-2 text-red-600">{{ errorMessage }}</p>
        }
        <app-form-buttons [form]="data.form" [formIsSubmitting]="isSubmitting" canCancel="false"></app-form-buttons>
      </div>
    </form>
  } @else {
    <div role="status" class="space-y-3.5">
      <div class="flex flex-col gap-x-12 flex-1 flex-wrap md:flex-row">
        <div class="flex flex-col gap-3 items-center mb-6 md:mb-0">
          <app-profile-photo input="loading" class="w-52"></app-profile-photo>
          <app-form-checkbox i18n-label label="Photo can be used in CV" isLoading="true"></app-form-checkbox>
        </div>
        <div class="flex flex-col flex-1 gap-4">
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="First name" isLoading="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Last name" isLoading="true" class="flex-1"></app-form-input>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="City of residence" isLoading="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Date of birth" isLoading="true" class="flex-1"></app-form-input>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="Title" isLoading="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="Email" isLoading="true" class="flex-1"></app-form-input>
          </div>
        </div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  }
</div>
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BuildInfoService } from 'src/app/core/services/build-info.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public deploymentInfo$: Observable<string>;

  constructor(buildInfoService: BuildInfoService) {
    this.deploymentInfo$ = buildInfoService
      .get()
      .pipe(map(buildInfo => `deployed from commit ${buildInfo.commitHash.slice(0, 7)} via build ${buildInfo.buildNumber}`));
  }
}

import { Company } from './company.model';
import { Role } from "./role";

export class User {
  constructor(
    public id: string,
    public profileId: string,
    public name: string,
    public email: string,
    public company: Company,
    public roles: Role[]) {}

  public get isSales(): boolean {
    return this.roles.indexOf(Role.SALES) > -1;
  }

  public get isAdmin(): boolean {
    return this.roles.indexOf(Role.ADMIN) > -1;
  }

  public get isProfessional(): boolean {
    return this.roles.indexOf(Role.PROFESSIONAL) > -1;
  }

  public static compare(user1: User, user2: User): number {
    if (!user1 && !user2) {
      return 0;
    } else if (!user1) {
      return -1;
    } else if (!user2) {
      return 1;
    } else if (user1.name === user2.name) {
      return 0;
    } else if (user1.name < user2.name) {
      return -1;
    } else {
      return 1;
    }
  }
}


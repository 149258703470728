import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';
import { Summary } from 'src/app/model/summary';

@Injectable()
export class SummaryListService extends ListService<Summary> {
  public listName = $localize `summaries`;

  protected getListItems(profile: Profile): ListItem<Summary>[] {
    return profile.summaries.map(summary => ({
      id: summary.id,
      title: summary.name,
      subTitle: '',
      originalItem: summary
    }));
  }
  
  protected updateProfile(profile: Profile, elements: Summary[]): void {
    profile.summaries = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteSummary(profileId, elementId);
  }
}

import { booleanAttribute, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { LabelComponent } from '../../basic/label/label.component';
import { CheckboxComponent } from '../../basic/checkbox/checkbox.component';

@Component({
  selector: 'app-form-checkbox',
  standalone: true,
  imports: [
    CheckboxComponent,
    FormErrorsComponent,
    LabelComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true
    }
  ],
  templateUrl: './form-checkbox.component.html'
})
export class FormCheckboxComponent implements ControlValueAccessor {
  protected value = false;
  protected isDisabled = false;
  protected onChange = (value: any) => {};
  protected onTouched = () => {};

  @Input({ required: true })
  public label = '';

  @Input()
  public bgColor: 'white' | 'default' = 'default';

  @Input({ transform: booleanAttribute })
  public isLoading = false;

  @Input({ transform: booleanAttribute })
  public isRequired = false;

  public onSelectionChange(checked: boolean): void {
    this.value = checked;
    this.onChange(checked);
  }

  public writeValue(value: boolean): void {
    this.value = value || false;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DateService } from "../services/date.service";

export function checkDateAfter(dateService: DateService, otherDateControlName: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
      if (control.parent) {
        if (control instanceof FormControl && control.parent instanceof FormGroup) {
          const otherDateControl = control.parent.get(otherDateControlName);
          const comparison = dateService.compareUiDate(control.value, otherDateControl.value);
          if (comparison <= 0 && control.dirty) {
            return {
              'dateAfter': $localize`The date ${control.value} must be after date ${otherDateControl.value}.`
            };
          }
        } else {
          console.error('This validator can only be applied to form controls directly in a form group.');
          return null;
        }
      }
    };
}

import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {filter, map} from 'rxjs/operators';

export const authenticationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const oidcSecurityService = inject(OidcSecurityService);

  return oidcSecurityService.isAuthenticated$.pipe(
    map(isAuthenticated => {
      if (!isAuthenticated) {
        oidcSecurityService.authorize(); // Redirect to login if not authenticated
        return false;
      }
      return true;
    })
  );
};

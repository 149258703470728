<div class="md:max-w-screen-lg md:mx-auto">
    @if (items$ | async; as items) {
        <div class="mb-4 flex flex-row">
            <h1 class="text-xl font-bold flex-1">{{ listName | uppercaseFirstLetter }}</h1>
            <a i18n-title type="button" title="Create" routerLink="./create" class="text-gray-700 hover:text-gray-900">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
            </a>
        </div>
        <ul class="flex flex-col" dndDropzone (dndDrop)="onDrop(items, $event)">
            <li class="py-5 bg-gray-50 border border-gray-200 rounded-lg" dndPlaceholderRef>
                <span class="font-medium collapse">Placeholder</span>
            </li>
            @for (item of items; track item.id) {
                <li class="flex flex-row items-center border-b-slate-100 group" [class.border-b]="!$last" [dndDraggable]="item" dndEffectAllowed="move" dndDragImageRef>
                    <div class="flex flex-col flex-1 min-w-0 md:gap-4 md:flex-row py-5 hover:cursor-move" dndHandle>
                        <span #spanTitle class="flex-[calc(2/3)] font-medium" appEllipsis>{{ item.title }}</span>
                        <span class="flex-[calc(1/3)] font-light text-gray-500">{{ item.subTitle | lowercase | uppercaseFirstLetter }}</span>
                    </div>
                    <div class="gap-1 pl-5 py-5 hidden md:flex md:invisible md:group-hover:visible">
                        <button i18n-title type="button" title="Delete" class="text-gray-700 hover:text-gray-900" (click)="modalDelete.open(item.id)">
                            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                            </svg>
                        </button>
                        <a i18n-title title="Edit" [routerLink]="['./', item.id]" class="text-gray-700 hover:text-gray-900">
                            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
                            </svg>
                        </a>
                    </div>
                    <div class="relative gap-1 flex md:hidden">
                        <button #btnMenu type="button" data-dropdown-placement="left-start" class="pl-5 py-5 text-gray-700 hover:text-gray-900">
                            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M12 6h.01M12 12h.01M12 18h.01"/>
                            </svg>
                        </button>
                        <div appDropdown [dropdownTrigger]="btnMenu" class="z-10 hidden bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44">
                            <ul class="py-2 text-sm text-gray-700">
                                <li>
                                    <a [routerLink]="['./', item.id]" class="block px-4 py-2 w-full text-left hover:bg-red-200">Edit</a>
                                </li>
                                <li>
                                    <button type="button" i18n class="block px-4 py-2 w-full text-left hover:bg-red-200" (click)="modalDelete.open(item.id)">Delete</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            } @empty {
                <li class="py-5">
                    <span i18n>No {{ listName | lowercase }} available. Please add one using the plus icon at the top.</span>
                </li>
            }
        </ul>
    } @else {
        <h1 class="text-xl font-bold flex-1 mb-4">{{ listName | uppercaseFirstLetter }}</h1>
        <ul role="status" class="flex flex-col animate-pulse">
            @for (_ of [].constructor(3); track $index) {
                <li class="py-5 border-b-slate-100" [class.border-b]="!$last">
                    <div class="flex flex-row items-center">
                        <div>
                            <div class="h-6 w-36 md:w-64 bg-gray-300 rounded-full"></div>
                        </div>
                        <div class="ml-auto flex gap-1">
                            @for (_ of [].constructor(2); track $index) {
                                <div class="h-6 w-6 bg-gray-300 rounded-full md:block" [class.hidden]="!$first"></div>
                            }
                        </div>
                    </div>
                </li>
            }
        </ul>
        <span class="sr-only">Loading...</span>
    }
    <app-modal-delete #modalDelete i18n-modalDeleteText modalDeleteText="Are you sure you want to delete this element?" (onDelete)="onDelete($event)"></app-modal-delete>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ChangeTrackerForm } from 'src/app/core/guards/block-nav-if-changes.guard';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Profile } from 'src/app/model/profile';

@Component({ template: '' })
export abstract class BaseProfileDataForm<T> extends ChangeTrackerForm implements OnInit {
  abstract id: string;
  abstract dataName: string;

  public title: string;
  public data$: Observable<Data>;
  public errorMessage = '';
  public isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    router: Router,
    protected profileService: ProfileService) {
      super(router);
  }

  protected abstract createForm(summary: T | null): FormGroup;
  protected abstract getInitialValue(profile: Profile, valueId: string): T | null;
  protected abstract save(profileId: string, form: FormGroup): Observable<any>;

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() === 'create';
    const action = isCreate ? $localize`Create` : $localize`Edit`;

    this.title = `${action} ${this.dataName}`;
    this.data$ = this.profileService
      .getActiveProfile()
      .pipe(
        tap(() => this.trackReset()),
        map<Profile, [Profile, FormGroup]>(
          (profile: Profile) => {
            if (isCreate) {
              return [ profile, this.createForm(null) ];
            } else {
              const initialValue = this.getInitialValue(profile, this.id);
              if (!initialValue) {
                throw new Error($localize`Unknown` + '' + this.dataName);
              } else {
                return [ profile, this.createForm(initialValue) ];
              }
            }
        }),
        map(([profile, form]) => ({
          profileId: profile.id,
          form: form,
          errorMessage: ''
        })),
        tap(data => this.trackForm(data.form)),
        catchError((err, caught) => {
          this.errorMessage = err.message;
          return caught;
        })
      );
  }

  public onFormSubmit(data: Data): void {
    if (data.form.valid && this.hasChanges()) {
      data.form.disable();
      this.isSubmitting = true;

      this
        .save(data.profileId, data.form)
        .subscribe({
          next: _ => {
            data.form.markAsPristine();
            this.trackedFormSubmitted();
            this.isSubmitting = false;
            data.form.enable();

            this.navigateBack();
          },
          error: (err: HttpErrorResponse) => {
            data.errorMessage = err.message;
            this.isSubmitting = false;
            data.form.enable();
          }
        });
    }
  }

  public onCancel(): void {
    this.trackReset();
    this.navigateBack();
  }

  private navigateBack(): void {
    this.forceNavigateRelativeTo(this.route, '../');
  }
}

interface Data {
  profileId: string;
  form: FormGroup;
  errorMessage: string;
}

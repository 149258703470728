import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-side-bar',
  standalone: true,
  templateUrl: './side-bar.component.html',
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkActive
  ]
})
export class SideBarComponent implements OnInit {
  public currentUser$: Observable<User>;

  constructor(private userService: UserService) {
  }

  public ngOnInit(): void {
    this.currentUser$ = this.userService.getCurrent();
  }
}

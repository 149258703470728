<div class="flex flex-col min-w-0 w-full h-full">
    <h2 i18n class="block mb-2 text-sm font-semibold text-gray-900">Assignments</h2>
    <div class="flex flex-col flex-1 gap-2 input-txt overflow-x-hidden overflow-y-auto">
        @if (isLoading()) {
            @for (_ of [].constructor(3); track $index) {
                <div class="flex flex-row gap-4">
                    <app-checkbox isLoading="true"></app-checkbox>
                    <div class="flex-1 flex gap-1 py-[2px]">
                        <div class="flex-1 flex flex-col gap-1">
                            <div class="w-32 h-4 bg-gray-300 rounded-xl animate-pulse"></div>
                            <div class="w-32 h-4 bg-gray-300 rounded-xl animate-pulse"></div>
                        </div>
                        <div class="w-32 h-4 bg-gray-300 rounded-xl animate-pulse"></div>
                    </div>
                </div>

            }
        } @else {
            @for (assignment of assignments(); track assignment.id) {
                <div class="flex flex-row gap-4">
                    <app-checkbox #checkbox [formControl]="controlArray().controls[$index]" bgColor="white"></app-checkbox>
                    <label [for]="checkbox.id()" class="flex-1 flex min-w-0">
                        <div class="flex-1 flex flex-col min-w-0">
                            <span class="text-sm font-medium text-gray-900 overflow-hidden" appEllipsis>{{ assignment.organisationNL }}</span>
                            <span class="text-sm font-normal text-gray-600 overflow-hidden" appEllipsis>{{ assignment.roleNL }}</span>
                        </div>
                        <span class="text-sm text-gray-600 w-40">{{ dateString(assignment) }}</span>
                    </label>
                </div>
            }
        }
    </div>
</div>

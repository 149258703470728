import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProfileService } from "../../../core/services/profile.service";
import { Profile } from "../../../model/profile";
import { AsyncPipe } from "@angular/common";
import { Professional } from 'src/app/model/professional';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { FormCheckboxComponent } from 'src/app/core/components/form/form-checkbox/form-checkbox.component';
import { ProfilePhotoComponent } from 'src/app/core/components/profile-photo/profile-photo.component';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';

@Component({
  selector: 'app-personal-details',
  standalone: true,
  templateUrl: './personal-details.component.html',
  imports: [
    AsyncPipe,
    DatePickerDirective,
    FormButtonsComponent,
    FormCheckboxComponent,
    FormInputComponent,
    ProfilePhotoComponent,
    ReactiveFormsModule
  ]
})
export class PersonalDetailsComponent implements OnInit {
  public profileChange$ = new ReplaySubject<Profile>(1);
  public data$: Observable<{ profile: Profile, form: FormGroup }>;
  public errorMessage = '';

  public isSubmitting = false;

  constructor(
    private dateService: DateService,
    private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.data$ = this.profileChange$.pipe(
      map(profile => ({ profile: profile, form: this.createForm(profile.professional) }))
    );

    this.profileService.getActiveProfile().subscribe({
      next: (profile) => this.profileChange$.next(profile),
      error: (err: HttpErrorResponse) => this.errorMessage = err.message
    });
  }

  public onFormSubmit(profile: Profile, form: FormGroup): void {
    if (form.valid && form.dirty) {
      this.isSubmitting = true;
      this.errorMessage = '';
      form.disable();

      const newProfile = {...profile};
      newProfile.professional.firstName = form.value['firstName'];
      newProfile.professional.lastName = form.value['lastName'];
      newProfile.professional.dateOfBirth = this.dateService.toApiDate(form.value['dateOfBirth']);
      newProfile.professional.placeOfResidence = form.value['placeOfResidence'];
      newProfile.professional.title = form.value['title'];
      newProfile.professional.emailAddress = form.value['emailAddress'];
      newProfile.professional.usePhoto = form.value['usePhoto'];
      
      this.profileService.updateProfile(newProfile).subscribe({
        next: profile => this.profileChange$.next(profile),
        error: (err: HttpErrorResponse) => this.errorMessage = err.message,
        complete: () => {
          form.enable();
          this.isSubmitting = false;
        }
      });
    }
  }

  private createForm(professional: Professional): FormGroup {
    const dateOfBirth = professional.dateOfBirth
      ? this.dateService.toUiDate(professional.dateOfBirth)
      : '';

    return new FormGroup({
      firstName: new FormControl(professional.firstName, [ Validators.required, Validators.minLength(2) ]),
      lastName: new FormControl(professional.lastName, [ Validators.required, Validators.minLength(2) ]),
      title: new FormControl(professional.title, Validators.required),
      dateOfBirth: new FormControl(dateOfBirth, Validators.required),
      placeOfResidence: new FormControl(professional.placeOfResidence, Validators.required),
      emailAddress: new FormControl(professional.emailAddress),
      usePhoto: new FormControl(professional.usePhoto)
    });
  }
}

import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [],
  templateUrl: './label.component.html'
})
export class LabelComponent {
  @Input({ required: true })
  public value = '';

  @Input({ required: true })
  public forId = '';

  @Input({ transform: booleanAttribute })
  public isBold = true;

  @Input({ transform: booleanAttribute })
  public isRequired = false;
}

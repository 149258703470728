<h1 i18n class="text-xl font-bold mb-4">Create user</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col gap-4">
  @if (isLoading()) {
    <app-form-input i18n-label label="Name" isLoading="true"></app-form-input>
    <app-form-input i18n-label label="Email" isLoading="true"></app-form-input>
    <app-form-select i18n-label label="Company" isLoading="true"></app-form-select>
  } @else {
    <app-form-input i18n-label label="Name" formControlName="name" isRequired="true"></app-form-input>
    <app-form-input i18n-label label="Email" formControlName="email" isRequired="true"></app-form-input>
    <app-form-select i18n-label label="Company" [options]="companyOptions" formControlName="company" isRequired="true"></app-form-select>
    @if (errorMessage) {
      <p class="mt-4 text-red-600">{{ errorMessage }}</p>
    }
    <app-form-buttons [form]="form" [formIsSubmitting]="isBusy" i18n-submitText submitText="Create" canCancel="false"></app-form-buttons>
  }
</form>

import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {RouterOutlet} from '@angular/router';
import {initFlowbite} from 'flowbite';
import {NavBarComponent} from './core/components/nav-bar/nav-bar.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {SideBarComponent} from './core/components/side-bar/side-bar.component';
import {polyfill} from 'mobile-drag-drop';
import {scrollBehaviourDragImageTranslateOverride} from 'mobile-drag-drop/scroll-behaviour';
import {ActiveProfileBannerComponent} from './core/components/active-profile-banner/active-profile-banner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ActiveProfileBannerComponent,
    NavBarComponent,
    RouterOutlet,
    SideBarComponent,
    FooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  public title = 'PRO';

  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  public ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(({isAuthenticated}) => {
      if (!isAuthenticated) {
        this.oidcSecurityService.authorize();
      }
    });
    initFlowbite();
    this.initMobileDragAndDrop();
  }

  private initMobileDragAndDrop(): void {
    // See https://github.com/reppners/ngx-drag-drop for more information.

    polyfill({
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
    });

    // workaround to make scroll prevent work in iOS Safari >= 10
    try {
      window.addEventListener('touchmove', () => {}, {passive: false});
    } catch (e) {
    }
  }
}

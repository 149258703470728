import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { IsMobileService } from '../services/is-mobile.service';

@Directive({
  selector: '[appAutogrow]',
  standalone: true
})
export class AutogrowDirective implements AfterViewInit {
  private element: HTMLTextAreaElement;
  private linkedToElement: HTMLTextAreaElement | null = null;

  @Input({ alias: 'appAutogrow' })
  public linkedToId: string | null = null;

  constructor(
    private isMobileService: IsMobileService,
    private elem: ElementRef) {
  }

  public ngAfterViewInit(): void {
    this.element = this.findTextarea(this.elem.nativeElement);
    this.element.style.overflow = 'hidden';
    this.element.style.resize = 'none';

    if (this.linkedToId) {
      const linkedToElem = document.getElementById(this.linkedToId);
      this.linkedToElement = this.findTextarea(linkedToElem);
    }

    this.applyHeight();
  }

  @HostListener('input')
  public onInputChanged(): void {
    this.applyHeight();
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.applyHeight();
  }

  protected applyHeight(): void {
    const linkedToHeight = this.getHeight(this.linkedToElement);
    const thisHeight = this.recalculateHeight();
    this.element.dataset.height = thisHeight.toString();

    if (this.isMobileService.isMobile()) {
      // Do not autogrow the linked element on mobile, only this element.
      this.setHeight(this.element, thisHeight);
    } else {
      const height = Math.max(thisHeight, linkedToHeight);
      this.setHeight(this.element, height);
      this.setHeight(this.linkedToElement, height);
    }
  }

  private recalculateHeight(): number {
    const baseHeight = this.element.offsetHeight - this.element.clientHeight;
    this.element.style.height = 'auto';
    return this.element.scrollHeight + baseHeight;
  }

  private findTextarea(elem: Element): HTMLTextAreaElement | null {
    if (elem instanceof HTMLTextAreaElement) {
      return elem;
    } else {
      for (let i = 0; i < elem.children.length; i++) {
        const textArea = this.findTextarea(elem.children.item(i));
        if (textArea) {
          return textArea;
        }
      }
      return null;
    }
  }

  private getHeight(elem: HTMLElement | null): number {
    if (!elem) {
      return 0;
    }

    return elem.dataset.height ? parseInt(elem.dataset.height) : 0;
  }

  private setHeight(elem: HTMLElement | null, height: number): void {
    if (elem) {
      elem.style.height = height + 'px';
    }
  }
}

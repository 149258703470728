import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { LogLevel, PassedInitialConfig, provideAuth } from 'angular-auth-oidc-client';
import { AuthHttpInterceptor } from './core/interceptor/auth.interceptor';

const authConfig: PassedInitialConfig = {
    config: {
        authority: 'https://login.microsoftonline.com/49445e6c-4079-4692-8349-8bb3853f22fc/v2.0/',
        redirectUrl: `${window.location.origin}`,
        clientId: 'aa4580d2-980e-4e6b-bd0f-3c22b45473a7',
        scope: 'openid profile email offline_access',
        responseType: 'code',
        triggerAuthorizationResultEvent: true,
        postLogoutRedirectUri: `${window.location.origin}/unauthorized`,
        startCheckSession: false,
        postLoginRoute: '/',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        logLevel: LogLevel.Debug,
        historyCleanupOff: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
    }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAuth(authConfig),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true
    },
    provideRouter(routes, withComponentInputBinding()),
    provideZoneChangeDetection({ eventCoalescing: true })
  ]
};

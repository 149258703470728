<h1 i18n class="text-xl font-bold mb-4">Import Recruitee profile</h1>

@if (isLoading()) {
    <div class="flex flex-col gap-4">
        <app-form-select i18n-label label="User" isLoading="true"></app-form-select>
        <app-form-input label="Recruitee ID" isLoading="true"></app-form-input>
    </div>
} @else {
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="flex flex-col gap-4">
        <app-form-input i18n-label label="User" formControlName="user" placeholder="john.doe@group9.nl" isRequired="true"></app-form-input>
        <app-form-input label="Recruitee ID" formControlName="recruiteeId" isRequired="true"></app-form-input>
        @if (successMessage) {
            <p class="text-green-600">{{ successMessage }}</p>
        }
        @if (errorMessage) {
            <p class="text-red-600">{{ errorMessage }}</p>
        }
        <app-form-buttons [canSubmit]="form.valid && hasChanges()" [formIsSubmitting]="isBusy" i18n-submitText submitText="Import" hideCancel="true"></app-form-buttons>
    </form>
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { Assignment } from 'src/app/model/assignment';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Injectable()
export class AssignmentListService extends ListService<Assignment> {
  public listName = $localize `professional experiences`;

  constructor(profileService: ProfileService, private dateService: DateService) {
    super(profileService);
  }

  protected getListItems(profile: Profile): ListItem<Assignment>[] {
    return profile.assignments.map(assignment => {
      const hasEndDate = assignment.endDate != null && assignment.endDate.trim() !== '';
      const endDateStr = hasEndDate ? this.dateService.toUiDate(assignment.endDate) : $localize`:Current moment in time:Present`;

      return {
        id: assignment.id,
        title: `${assignment.organisationNL} - ${assignment.roleNL}`,
        subTitle: `${this.dateService.toUiDate(assignment.startDate)} - ${endDateStr}`,
        originalItem: assignment
      };
    });
  }
  
  protected updateProfile(profile: Profile, elements: Assignment[]): void {
    profile.assignments = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteAssignment(profileId, elementId);
  }
}

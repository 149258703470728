import { Injectable } from '@angular/core';
import { BuildInfo } from '../../model/build-info';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildInfoService {
  private buildInfo$ = new ReplaySubject<BuildInfo>(1);
  private hasRetrieved = false;

  public readonly defaultBuildInfo: BuildInfo = {
    commitHash: 'local',
    buildNumber: 'xxxxxxxx.x'
  };

  constructor(private httpClient: HttpClient) {
    this.buildInfo$.next(this.defaultBuildInfo);
  }

  public get(): Observable<BuildInfo> {
    if (!this.hasRetrieved) {
      this.hasRetrieved = true;

      this.httpClient
        .get<BuildInfo>('build-info.json')
        .subscribe({
          next: info => this.buildInfo$.next(info),
          error: () => this.buildInfo$.next(this.defaultBuildInfo)
        });
    }

    return this.buildInfo$;
  }
}

import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Assignment } from 'src/app/model/assignment';
import { Profile } from 'src/app/model/profile';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { FormTextareaComponent } from 'src/app/core/components/form/form-textarea/form-textarea.component';
import { FormLanguageContainerComponent } from 'src/app/core/components/form/form-language-container/form-language-container.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { checkDateAfter } from 'src/app/core/validation/check-date-after';
import { FormToolsComponent } from 'src/app/core/components/form/form-tools/form-tools.component';
import { AutogrowDirective } from 'src/app/core/directives/autogrow.directive';
import { TextService } from 'src/app/core/services/text.service';
import { checkDateBefore } from 'src/app/core/validation/check-date-before';

@Component({
  selector: 'app-assignment-form',
  standalone: true,
  imports: [
    AsyncPipe,
    AutogrowDirective,
    DatePickerDirective,
    FormButtonsComponent,
    FormInputComponent,
    FormLanguageContainerComponent,
    FormTextareaComponent,
    FormToolsComponent,
    ReactiveFormsModule
  ],
  templateUrl: './assignment-form.component.html'
})
export class AssignmentFormComponent extends BaseProfileDataForm<Assignment> {
  @Input()
  public id: string;
  public dataName = $localize`professional experience`;

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService,  private dateService: DateService, private textService: TextService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, valueId: string): Assignment | null {
    return profile.assignments.find(assignment => assignment.id === valueId);
  }

  protected createForm(assignment: Assignment | null): FormGroup {
    const tools = (assignment?.tools ?? [])
      .map(tool => new FormControl(tool));

    return new FormGroup(
      {
        id: new FormControl(assignment?.id),
        nlOrganisation: new FormControl(assignment?.organisationNL, Validators.required),
        nlRole: new FormControl(assignment?.roleNL, Validators.required),
        nlDescription: new FormControl(this.textService.addDuplicateNewlines(assignment?.descriptionNL), Validators.required),
        enOrganisation: new FormControl(assignment?.organisationEN),
        enRole: new FormControl(assignment?.roleEN),
        enDescription: new FormControl(this.textService.addDuplicateNewlines(assignment?.descriptionEN)),
        startDate: new FormControl(this.dateService.toUiDate(assignment?.startDate), Validators.required),
        endDate: new FormControl(this.dateService.toUiDate(assignment?.endDate), checkDateAfter(this.dateService, 'startDate')),
        tools: new FormArray(tools)
      }, checkDateBefore(this.dateService, 'startDate', 'endDate'));
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    const initialTools = this.getTools(initialValue['tools']);
    const currentTools = this.getTools(currentValue['tools']);

    return initialValue['id'] === currentValue['id']
      && initialValue['nlOrganisation'] === currentValue['nlOrganisation']
      && initialValue['nlRole'] === currentValue['nlRole']
      && this.textService.removeDuplicateNewLines(initialValue['nlDescription']) === this.textService.removeDuplicateNewLines(currentValue['nlDescription'])
      && (initialValue['enOrganisation'] ?? '') === (currentValue['enOrganisation'] ?? '')
      && (initialValue['enRole'] ?? '') === (currentValue['enRole'] ?? '')
      && this.textService.removeDuplicateNewLines(initialValue['enDescription']) === this.textService.removeDuplicateNewLines(currentValue['enDescription'])
      && this.dateService.toApiDate(initialValue['startDate']) === this.dateService.toApiDate(currentValue['startDate'])
      && this.dateService.toApiDate(initialValue['endDate']) === this.dateService.toApiDate(currentValue['endDate'])
      && initialTools.length === currentTools.length
      && initialTools.every((initialTool, idx) => initialTool === currentTools[idx]);
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addAssignment(
      profileId,
      {
        id: form.value['id'],
        organisationNL: form.value['nlOrganisation'],
        roleNL: form.value['nlRole'],
        descriptionNL: this.textService.removeDuplicateNewLines(form.value['nlDescription']),
        organisationEN: form.value['enOrganisation'] ?? '',
        roleEN: form.value['enRole'] ?? '',
        descriptionEN: this.textService.removeDuplicateNewLines(form.value['enDescription']),
        startDate: this.dateService.toApiDate(form.value['startDate']),
        endDate: this.dateService.toApiDate(form.value['endDate']),
        tools: this.getTools(form.value['tools'])
      });
  }

  private getTools(value: any): string[] {
    return value
      .map((tool: string) => tool.trim())
      .filter((tool: string) => tool !== '');
  }
}

import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AsyncPipe} from '@angular/common';
import {AuthService, emptyToken, Token} from '../../services/auth.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-side-bar',
  standalone: true,
  templateUrl: './side-bar.component.html',
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkActive
  ]
})
export class SideBarComponent implements OnInit {
  public token$: Observable<Token>;

  constructor(private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.token$ = this.authService.getIdTokenClaims().pipe(
      map((tokenClaims) => tokenClaims || emptyToken)
    );
  }
}

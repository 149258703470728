import { AfterViewInit, Directive, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { Datepicker } from 'flowbite';
import * as moment from 'moment';

@Directive({
  selector: '[appDatePicker]',
  standalone: true
})
export class DatePickerDirective implements AfterViewInit {
  private dateFormat: string;
  private datePicker: Datepicker;
  private inputElem: HTMLInputElement;

  constructor(@Inject(LOCALE_ID) private locale: string, private elem: ElementRef) {
    this.dateFormat = moment.localeData(locale).longDateFormat('L');
  }

  public ngAfterViewInit(): void {
    this.inputElem = this.findInput(this.elem.nativeElement);
    this.inputElem.addEventListener('changeDate', () => this.onDateChanged());

    this.datePicker = new Datepicker(
      this.inputElem,
      {
        autohide: true,
        language: this.locale,
        format: this.dateFormat.toLowerCase()
      });
    this.datePicker.init();
  }

  public onDateChanged(): void {
    const e = new Event('input', { bubbles: true });
    this.inputElem.dispatchEvent(e);
  }

  private findInput(elem: Element): HTMLInputElement | null {
    if (elem instanceof HTMLInputElement) {
      return elem;
    } else {
      for (let i = 0; i < elem.children.length; i++) {
        const input = this.findInput(elem.children.item(i));
        if (input) {
          return input;
        }
      }
      return null;
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';
import { Tool, toolLevelToName, toolTypeToName } from 'src/app/model/tool';

@Injectable()
export class ToolListService extends ListService<Tool> {
  public listName = $localize `tools & techniques`;

  protected getListItems(profile: Profile): ListItem<Tool>[] {
    return profile.tools.map(tool => ({
      id: tool.id,
      title: `${toolTypeToName(tool.toolType)} - ${tool.name}`,
      subTitle: toolLevelToName(tool.level),
      originalItem: tool
    }));
  }
  
  protected updateProfile(profile: Profile, elements: Tool[]): void {
    profile.tools = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteTool(profileId, elementId);
  }
}

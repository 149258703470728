import {Component, OnInit} from '@angular/core';
import {Profile} from '../model/profile';
import {ProfileService} from '../core/services/profile.service';
import {CvService} from '../core/services/cv.service';
import {CurriculumVitaeSpecification} from '../model/curriculumVitaeSpecification';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {Companies} from '../model/company.model';
import {map} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {SelectOption} from '../core/components/basic/select/select-option.model';
import {SelectComponent} from '../core/components/basic/select/select.component';
import {LabelComponent} from '../core/components/basic/label/label.component';
import {FormSelectComponent} from '../core/components/form/form-select/form-select.component';
import {FormCheckboxComponent} from '../core/components/form/form-checkbox/form-checkbox.component';
import {FormAssignmentsComponent} from '../core/components/form/form-assignments/form-assignments.component';
import {FormButtonsComponent} from '../core/components/form/form-buttons/form-buttons.component';
import {Professional} from '../model/professional';
import {ProfessionalService} from '../core/services/professional.service';

@Component({
  selector: 'app-download',
  standalone: true,
  imports: [
    AsyncPipe,
    FormAssignmentsComponent,
    FormButtonsComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    LabelComponent,
    ReactiveFormsModule,
    SelectComponent
  ],
  templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {
  public static readonly MAX_NR_OF_DETAILED_ASSIGNMENTS = 5;

  public languageOptions: SelectOption<string>[] = [
    {value: 'DUTCH', label: $localize`Dutch`},
    {value: 'ENGLISH', label: $localize`English`}
  ];

  public templateOptions = Companies.getAllCompanyOptions();

  public data$: Observable<Data>;
  public form$: Observable<FormGroup>;
  public errorMessage = '';
  public isGenerating = false;

  constructor(
    private professionalService: ProfessionalService,
    private profileService: ProfileService,
    private cvService: CvService) {
  }

  public ngOnInit(): void {
    this.data$ = combineLatest([this.professionalService.getAll(), this.profileService.getActiveProfile()]).pipe(
      map(([availableUsers, activeProfile]) => ({
        profileId: activeProfile.id,
        form: this.createForm(activeProfile),
        selectedProfile: activeProfile,
        options: this.createOptions(availableUsers, activeProfile)
      }))
    );

    this.form$ = this.profileService.getActiveProfile().pipe(
      map((activeProfile) => this.createForm(activeProfile))
    );
  }

  public onProfileSelectionChange(profileId: string): void {
    if (profileId) {
      this.profileService.setActiveProfile(profileId);
    }
  }

  public generateCV(data: Data, form: FormGroup): void {
    this.errorMessage = '';
    this.isGenerating = true;
    form.disable();

    const assignmentsValue = form.value.assignments as boolean[];
    const assignmentIds = assignmentsValue
      .map((selected, idx) => ({
        assignmentId: data.selectedProfile.assignments[idx].id,
        selected
      }))
      .filter(item => item.selected)
      .map(item => item.assignmentId);

    const maxNrOfDetailedAssignments = Math.min(assignmentIds.length, form.value.maxNrOfDetailedAssignments);

    const cv: CurriculumVitaeSpecification = {
      profileId: data.profileId,
      language: form.value.language,
      summaryId: form.value.summary,
      company: form.value.template,
      usePhoto: form.value.usePhoto,
      selectAssignments: assignmentIds,
      maxEducation: parseInt(form.value.maxNrOfEducations, 10),
      maxCertificates: parseInt(form.value.maxNrOfCertificates, 10),
      maxAssignmentsDetail: maxNrOfDetailedAssignments,
      maxAssignmentsSummary: assignmentIds.length - maxNrOfDetailedAssignments
    };

    this.cvService
      .generateCV(cv)
      .subscribe({
        next: blob => {
          this.downloadFile(data.selectedProfile, blob);
          form.enable();
          this.isGenerating = false;
        },
        error: err => {
          this.errorMessage = $localize`Something went wrong while downloading the CV. Please contact the system administrator. ${err.message}`;
          form.enable();
          this.isGenerating = false;
        }
      });
  }

  private createForm(selectedProfile: Profile): FormGroup {
    const {
      professional,
      summaries,
      assignments,
      educations,
      certificates
    } = selectedProfile;

    return new FormGroup({
      language: new FormControl(this.languageOptions[0].value, Validators.required),
      template: new FormControl(professional.company, Validators.required),
      usePhoto: new FormControl({value: professional.usePhoto, disabled: !professional.usePhoto}),
      summary: new FormControl(summaries.length > 0 ? summaries[0].id : null, Validators.required),
      assignments: new FormArray(assignments.map(_ => new FormControl(true))),
      maxNrOfDetailedAssignments: new FormControl(Math.min(assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS), Validators.required),
      maxNrOfEducations: new FormControl(educations.length, Validators.required),
      maxNrOfCertificates: new FormControl(certificates.length, Validators.required)
    });
  }

  private createOptions(availableUsers: Professional[], activeProfile: Profile): Options {
    return {
      profiles: availableUsers.map(user => ({
        value: user.emailAddress,
        label: `${user.firstName} ${user.lastName} (${Companies.companyToName(user.company)})`
      })),
      summaries: activeProfile.summaries.map(summary => ({
        value: summary.id,
        label: summary.name
      })),
      maxNrOfDetailedAssignments: this.numberOptions(activeProfile.assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS),
      maxNrOfEducations: this.numberOptions(activeProfile.educations.length),
      maxNrOfCertificates: this.numberOptions(activeProfile.certificates.length)
    };
  }

  private numberOptions(length: number, maxLength?: number | null): SelectOption<number>[] {
    const limitedLength = maxLength ? Math.min(length, maxLength) : length;
    const actualLength = limitedLength + 1; // Add 1 for the value '0'.

    return Array(actualLength)
      .fill(null)
      .map((_, idx: number) => ({
        value: idx,
        label: idx.toString()
      }));
  }

  private getCurrentTimestamp(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}`;
  }

  private downloadFile(selectedProfile: Profile, blob: Blob): void {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `CV ${selectedProfile.professional.fullName} - ${this.getCurrentTimestamp()}.docx`;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
}

interface Options {
  profiles: SelectOption<string>[];
  summaries: SelectOption<string>[];
  maxNrOfDetailedAssignments: SelectOption<number>[];
  maxNrOfEducations: SelectOption<number>[];
  maxNrOfCertificates: SelectOption<number>[];
}

interface Data {
  profileId: string;
  selectedProfile: Profile | null;
  options: Options;
}

import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appEllipsis]',
  standalone: true
})
export class EllipsisDirective implements AfterViewInit {
  constructor(private elem: ElementRef) {
    elem.nativeElement.classList.add('overflow-hidden', 'text-ellipsis', 'whitespace-nowrap');
  }

  public ngAfterViewInit(): void {
    if(this.elem.nativeElement.offsetWidth < this.elem.nativeElement.scrollWidth){
      this.elem.nativeElement.title = this.elem.nativeElement.innerHTML;
    }
  }
}

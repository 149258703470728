<div class="flex flex-col gap-4 md:max-w-screen-lg md:mx-auto">
    <h1 class="text-xl font-bold">{{ title }}</h1>

    @if (errorMessage) {
        <p class="text-red-600">{{ errorMessage }}</p>
    } @else {
        @if (data$ | async; as data) {
            <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
                <app-form-input i18n-label label="Name" formControlName="name" isRequired="true"></app-form-input>
                <app-form-language-container>
                    <div slot="dutch">
                        <app-form-textarea id="summaryNL" i18n-label label="Summary" formControlName="nl" isRequired="true" appAutogrow="summaryEN" bgColor="white"></app-form-textarea>
                    </div>
                    <div slot="english">
                        <app-form-textarea id="summaryEN" i18n-label label="Summary" formControlName="en" isRequired="true" appAutogrow="summaryNL" bgColor="white"></app-form-textarea>
                    </div>
                </app-form-language-container>
                @if (data.errorMessage) {
                    <p class="text-red-600">{{ data.errorMessage }}</p>
                }
                <app-form-buttons [form]="data.form" [(formIsSubmitting)]="isSubmitting" (onCancel)="navigateBack()"></app-form-buttons>
            </form>
        } @else {
            <div role="status" class="flex flex-col gap-4">
                <app-form-input i18n-label label="Name" isLoading="true"></app-form-input>
                <app-form-language-container>
                    <div slot="dutch">
                        <app-form-textarea i18n-label label="Summary" isLoading="true"></app-form-textarea>
                    </div>
                    <div slot="english">
                        <app-form-textarea i18n-label label="Summary" isLoading="true"></app-form-textarea>
                    </div>
                </app-form-language-container>
                <span class="sr-only">Loading...</span>
            </div>
        }
    }
</div>
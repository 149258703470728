import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurriculumVitaeSpecification } from '../../model/curriculumVitaeSpecification';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CvService {
  constructor(private http: HttpClient) { }

  public generateCV(cv: CurriculumVitaeSpecification): Observable<Blob> {
    const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    const headers = new HttpHeaders()
      .set('Accept', mimeType);

    return this.http
      .post(`/api/cv`, cv, { headers: headers, responseType: 'blob'})
      .pipe(
        map(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          return new Blob([x], { type: mimeType });
        })
      );
  }
}

import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    RouterLink,
    SpinnerComponent
  ],
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  public color = input.required<'primary' | 'secondary'>();
  public type = input.required<'button' | 'submit'>();
  public label = input.required<string>();
  public isDisabled = input(false, { transform: booleanAttribute });
  public isPerformingAction = input(false, { transform: booleanAttribute });

  public classes = computed(() => {
    const shared = 'flex gap-2 text-white justify-center font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none w-full focus:ring-4 hover:cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-200';

    switch (this.color()) {
      case 'primary': return `${shared} bg-alfa1-brand-primary-500 hover:bg-alfa1-brand-primary-700 focus:ring-red-300`;
      case 'secondary': return `${shared} bg-gray-500 hover:bg-gray-700 focus:ring-gray-300`;
    }
  });

  public onBtnClick = output<void>();
}
